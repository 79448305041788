/* eslint-disable simple-import-sort/imports */
import {
  AmbientLight,
  Color,
  DirectionalLight,
  DirectionalLightHelper,
  SpotLight,
} from 'three';
import { WebGLContainer } from './webglContainer';
import { LIGHT_SETTINGS } from 'constants/scene';
import GUI from 'lil-gui';
import { parseUrlQuery } from 'u9/utils/url';

export class Lights {
  static ambientLight: AmbientLight;
  static directionalLight: DirectionalLight;
  static pointLight: SpotLight;
  static active: boolean;

  static build() {
    Lights.ambientLight = new AmbientLight(
      '#ffffff',
      LIGHT_SETTINGS.ambient.intensity
    );
    WebGLContainer.mainScene.add(Lights.ambientLight);

    const directionalSettings = LIGHT_SETTINGS.directional;
    Lights.directionalLight = new DirectionalLight(
      '#ffffff',
      directionalSettings.intensity
    );
    Lights.directionalLight.position.set(
      directionalSettings.position.x,
      directionalSettings.position.y,
      directionalSettings.position.z
    );
    Lights.directionalLight.lookAt(0, 0, 0);

    WebGLContainer.mainScene.add(Lights.directionalLight);

    Lights.pointLight = new SpotLight(0xcccccc, 0);
    Lights.pointLight.position.set(0.1, 0.12, 5.1);
    Lights.pointLight.angle = Math.PI * 0.2;
    Lights.pointLight.castShadow = true;
    Lights.pointLight.shadow.camera.near = 0.1;
    Lights.pointLight.shadow.camera.far = 200;
    Lights.pointLight.shadow.bias = -0.000222;
    Lights.pointLight.shadow.mapSize.width = 1024;
    Lights.pointLight.shadow.mapSize.height = 1024;
    WebGLContainer.mainScene.add(Lights.pointLight);

    Lights.active = true;

    if (process.env.IS_DEBUG && parseUrlQuery().debugPostPro) {
      this.setupDebugger();
    }
  }

  static setupDebugger() {
    if (!Lights.active) return;
    if (!WebGLContainer.dat) {
      WebGLContainer.dat = new GUI();
    }
    WebGLContainer.dat.close();
    const helper = new DirectionalLightHelper(Lights.directionalLight, 4);
    WebGLContainer.mainScene.add(helper);

    const lightsF = WebGLContainer.dat.addFolder('Global Lights');
    lightsF.close();

    const lightF = lightsF.addFolder('Directional Light');
    lightF.close();
    const ambF = lightsF.addFolder('Ambient Light');
    ambF.close();
    const pointF = lightsF.addFolder('Point Light');
    pointF.close();

    const params = {
      dirColor: LIGHT_SETTINGS.directional.color,
      ambientColor: LIGHT_SETTINGS.ambient.color,
      pointColor: LIGHT_SETTINGS.directional.color,
      camColor: '#ffffff',
    };
    lightF.addColor(params, 'dirColor').onChange(() => {
      Lights.directionalLight.color = new Color(params.dirColor);
    });
    lightF
      .add(Lights.directionalLight.position, 'x', -100.0, 100.0)
      .step(0.001);
    lightF.add(Lights.directionalLight.position, 'y', 0.0, 100.0).step(0.001);
    lightF
      .add(Lights.directionalLight.position, 'z', -100.0, 100.0)
      .step(0.001);
    lightF.add(Lights.directionalLight, 'intensity', 0.0, 3.0).step(0.001);

    pointF.addColor(params, 'pointColor').onChange(() => {
      Lights.pointLight.color = new Color(params.dirColor);
    });
    pointF.add(Lights.pointLight.position, 'x', -100.0, 100.0).step(0.001);
    pointF.add(Lights.pointLight.position, 'y', 0.0, 100.0).step(0.001);
    pointF.add(Lights.pointLight.position, 'z', -100.0, 100.0).step(0.001);
    pointF.add(Lights.pointLight, 'intensity', 0.0, 3.0).step(0.001);

    ambF.addColor(params, 'ambientColor').onChange(() => {
      Lights.ambientLight.color = new Color(params.ambientColor);
    });
    ambF.add(Lights.ambientLight, 'intensity', 0.0, 1.0).step(0.001);
  }
}
