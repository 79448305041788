import React, { useState } from 'react';

import useThemeStore from 'store/visuals';

import * as Styled from './Button.styles';

export interface ButtonProps {
  onClick?: (any) => void;
  label: string;
  imgUrl?: string;
  children?: React.ReactElement;
  whiteBg?: boolean;
  vertical?: boolean;
  className?: string;
  hasOwnAnimation?: boolean;
  disabled?: boolean;
  forceCopy?: boolean;
  showLabel?: boolean;
}

const defaultProps: Partial<ButtonProps> = {};

const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  imgUrl,
  children,
  whiteBg,
  vertical,
  className,
  hasOwnAnimation = false,
  disabled = false,
  forceCopy = false,
  showLabel = true,
}) => {
  const [animation, setAnimation] = useState(false);
  const { theme } = useThemeStore();

  const triggerAnimation = () => {
    setAnimation(prevState => {
      return !prevState;
    });
  };

  const labelComponent = () => {
    const component =
      theme.ctaAsText || forceCopy ? (
        <span dangerouslySetInnerHTML={{ __html: label }} />
      ) : (
        <img alt={label} src={imgUrl} />
      );
    return component;
  };

  return (
    <Styled.Button
      onClick={hasOwnAnimation ? onClick : triggerAnimation}
      $whiteBg={whiteBg}
      $icon={!!children}
      $vertical={vertical}
      $animate={hasOwnAnimation ? false : animation}
      className={className}
      onAnimationEnd={!hasOwnAnimation && onClick ? e => onClick(e) : undefined}
      disabled={disabled}
    >
      {children ? (
        <>
          {children}
          {showLabel && labelComponent()}
        </>
      ) : (
        <>{showLabel && labelComponent()}</>
      )}
    </Styled.Button>
  );
};

Button.defaultProps = defaultProps;

export default React.memo(Button);
