import GUI from 'lil-gui';
import {
  DoubleSide,
  MeshPhysicalMaterial,
  RepeatWrapping,
  sRGBEncoding,
  Vector2,
} from 'three';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { parseUrlQuery } from 'u9/utils/url';

import { clearcoat, clearcoatRoughness, metalness, roughness } from './config';

export const adjustTexture = texture => {
  texture.encoding = sRGBEncoding;
  texture.center = new Vector2(0.5, 0.5);
  texture.rotation = Math.PI;
  texture.wrapS = RepeatWrapping;
  texture.repeat.x = -1;
};

export const getCanMaterial = texture => {
  adjustTexture(texture);
  const material = new MeshPhysicalMaterial({
    envMap: WebGLContainer.dictionary.envMap,
    map: texture,
    metalness: metalness,
    roughness: roughness,
    clearcoat: clearcoat,
    clearcoatRoughness: clearcoatRoughness,
  });

  if (process.env.IS_DEBUG && parseUrlQuery().debugPostPro) {
    if (!WebGLContainer.dat) {
      WebGLContainer.dat = new GUI();
    }

    const canF = WebGLContainer.dat.addFolder('Can');
    canF.close();
    canF.add(material, 'metalness', 0.0, 1.0, 0.01);
    canF.add(material, 'roughness', 0.0, 1.0, 0.01);
    canF.add(material, 'clearcoat', 0.0, 1.0, 0.01);
    canF.add(material, 'clearcoatRoughness', 0.0, 1.0, 0.01);
  }

  material.side = DoubleSide;

  return material;
};

export const getMeshWhereName = (children, name) =>
  children.find(child => child.name === name);
