export enum MARKET {
  VI = 'vi',
  PK = 'pk',
  MX = 'mx',
  International = 'international',
}

export enum AiError {
  none = 'none',
  oops = 'oops',
  capacity = 'capacity',
}
