/* eslint-disable prettier/prettier */
import { keyframes } from 'styled-components';

export const spin = () => keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(1turn); }
`;

export const floatingDown = (transform: string, up: boolean) => keyframes`
  0% { transform:  ${`translateY(${up ? '44' : '40'}vw)`} ${transform}; }
  50% { transform:  ${`translateY(${up ? '40' : '44'}vw)`} ${transform};  }
  100% { transform:  ${`translateY(${up ? '44' : '40'}vw)`} ${transform}; }
`;

export const floatUp = (transform: string) => keyframes`
  0% { transform: translateY(0px) ${transform}; }
  70% { transform: translateY(-700px) ${transform};  }
  70.001% { transform: translateY(1500px) ${transform}; }
  100% { transform: translateY(1500px) ${transform}; }
`;

export const floatUpBigBall = (transform: string) => keyframes`
  0% { transform: translateY(0px) ${transform}; }
  100% { transform: translateY(-1500px) ${transform}; }
`;

export const floatUpFaster = (transform: string) => keyframes`
  0% { transform: translateY(0px) ${transform}; }
  50% { transform: translateY(-500px) ${transform};  }
  50.001% { transform: translateY(1000px) ${transform}; }
  80% { transform: translateY(300px) ${transform}; }
  100% { transform: translateY(0px) ${transform}; }
`;

export const floatUpNewElements = (transform: string) => keyframes`
  0% { transform: translateY(700px) ${transform}; opacity: 0 }
  1% { transform: translateY(700px) ${transform}; opacity: 1 }
  70% { transform: translateY(0px) ${transform}; opacity: 1   }
  100% { transform: translateY(-700px) ${transform}; opacity: 1  }
`;

export const floatingSpecial = (transform: string, up: boolean) => keyframes`
  0% { transform:  translateY(0px) ${transform}; }
  50% { transform:  ${`translateY(${up ? '10' : '-10'}px)`} ${transform};  }
  100% { transform:  translateY(0px) ${transform}; }
`;

export const rotateAroundCanGlasses = () => keyframes`
  0% {
    z-index: 0;
    offset-distance: 100%;
    scale: 1;
  }
  15% {
    z-index: 0;
    scale: 0.6;
  }
  16% {
    z-index: 0;
    scale: 0.6;
  }
  36% {
    z-index: 10;
  }
  50% {
    z-index: 10;
    scale: 1.3;
  }
  100% {
    z-index: 10;
    offset-distance: 0%;
    scale: 1;
  }
`;

export const rotateAroundCanTounge = () => keyframes`
  0% {
    z-index: 10;
    offset-distance: 0%;
    scale: 1;
  }
  15% {
    z-index: 10;
  }
  16% {
    z-index: 10;
    scale: 1.2;
  }
  20% {
    z-index: 10;
  }
  45% {
    z-index: 0;
    scale: 0.7;
  }
  100% {
    z-index: 0;
    offset-distance: 100%;
    scale: 1;
  }
`;

export const rotateAroundCanHash0 = () => keyframes`
  0% {
    z-index: 3;
    offset-distance: 100%;
    opacity: 0;
    scale: 0.6;
  }
  1% {
    z-index: 3;
    opacity: 1;
  }
  11% {
    z-index: 3;
    opacity: 1;
  }
  12% {
    z-index: 9;
    opacity: 1;
  }
  40% {
    z-index: 9;
    opacity: 1;
    scale: 1.1;
  }
  77% {
    z-index: 3;
    opacity: 1;
    scale: 0.8;
  }
  100% {
    z-index: 3;
    opacity: 1;
    offset-distance: 0%;
    scale: 0.7;
  }
`;

export const rotateAroundCanHash1 = () => keyframes`
  0% {
    z-index: 3;
    offset-distance: 100%;
    opacity: 0;
    scale: 0.6;
  }
  1% {
    z-index: 3;
    opacity: 1;
  }
  15% {
    z-index: 3;
    opacity: 1;
  }
  20% {
    z-index: 9;
    opacity: 1;
  }
  70% {
    z-index: 9;
    opacity: 1;
    scale: 1;
  }
  100% {
    z-index: 9;
    offset-distance: 0%;
    opacity: 1;
    scale: 1;
  }
`;

export const rotateAroundCanHashBehindCan = () => keyframes`
  0% {
    z-index: 3;
    offset-distance: 100%;
    opacity: 0;
  }
  1% {
    z-index: 3;
    opacity: 1;
  }
  100% {
    z-index: 3;
    offset-distance: 0%;
    opacity: 1;
  }
`;

export const rotateCan = keyframes`
  0% { transform: rotate(15deg) }
  50% { transform: rotate(0) }
  100% { transform: rotate(-15deg) }
`;

export const buttonTap = () => keyframes`
  0% { transform: scale(1); opacity: 1;}
  50% { transform: scale(0.9); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1;}
`;

export const pulse = (transform?: string) => keyframes`
  0% { opacity: 0; transform: ${transform ? `${transform} ` : ''}scale(0.5); }
  40%, 45% { opacity: 1; transform: ${
    transform ? `${transform} ` : ''
  }scale(1); }
  60%, 100% { opacity: 0; transform: ${
    transform ? `${transform} ` : ''
  }scale(0.95); }
`;

export const pageMotionProps = {
  initial: { opacity: 0, x: '50rem' },
  animate: { opacity: 1, x: '0rem' },
  exit: { opacity: 0, x: '-50rem' },
  transition: { duration: 0.3 },
};

export const fadeMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 },
};

export const processingCanMotionProps = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0 },
  transition: {
    duration: 0.2,
    ease: [0.75, 0.82, 0.165, 1],
  },
};

export const loginMotionProps = {
  initial: { opacity: 1, y: '100%' },
  animate: { opacity: 1, y: '0%' },
  exit: { opacity: 1, y: '100%' },
  transition: { duration: 0.8 },
};

export const getBannerMotionProps = (from: 'top' | 'bottom') => ({
  initial: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  animate: { opacity: 1, y: '0%' },
  exit: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  transition: { duration: 0.6 },
});
