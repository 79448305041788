export const ROUTES = {
  HOME: '/',
  HOME_PK: '/pk',
  HOME_SA: '/sa',
  CAN: '/can',
  NOT_FOUND: '/404',
} as const;

export enum Pages {
  index = 'index',
  about = 'about',
  can = 'can',
  notFound = 'notFound',
}

export const SPA_ROUTES = {
  LOADER: 'loader',
  LANDING: 'landing',
  INTRO: 'intro',
  SOURCESELECTION: 'sourceSelection',
  FAKEGALLERY: 'fakeGallery',
  GALLERYPICKER: 'galleryPicker',
  PROCESSING: 'processing',
  ARTWORK: 'artwork',
} as const;

// TO DO: adjust routes/flow to current designs
export const SPA_ROUTES_PREV = {
  [SPA_ROUTES.INTRO]: SPA_ROUTES.LANDING,
  [SPA_ROUTES.SOURCESELECTION]: SPA_ROUTES.INTRO,
  [SPA_ROUTES.FAKEGALLERY]: SPA_ROUTES.GALLERYPICKER,
  [SPA_ROUTES.GALLERYPICKER]: SPA_ROUTES.INTRO,
  [SPA_ROUTES.PROCESSING]: SPA_ROUTES.SOURCESELECTION,
  [SPA_ROUTES.ARTWORK]: SPA_ROUTES.GALLERYPICKER,
};
