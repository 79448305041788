import { AnimatePresence } from 'framer-motion';
import React from 'react';

import logo from 'assets/img/mirindaLogo.png';
import AppBackground from 'components/AppBackground/AppBackground';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { ReactComponent as Icon } from 'svgs/noWebGL.svg';
import { fadeMotionProps } from 'utils/styles/animations';

import * as NonFunctionalsStyles from './../NonFunctionals.styles';

interface WebGLDisabled {
  copy: GlobalCopy['errors']['webGLDisabled'];
}

const WebGLDisabled = ({ copy }: WebGLDisabled) => {
  const { theme } = useThemeStore();

  return (
    <AnimatePresence>
      <NonFunctionalsStyles.Wrapper {...fadeMotionProps}>
        <AppBackground />
        <div>
          <img alt="logo" src={logo} />
          <NonFunctionalsStyles.Card>
            <Icon />
            <img alt={copy.title} src={theme.headings?.modalNoWebGL} />
            <NonFunctionalsStyles.Description
              dangerouslySetInnerHTML={{ __html: copy.body1 }}
            />
            <NonFunctionalsStyles.Description
              dangerouslySetInnerHTML={{ __html: copy.body2 }}
            />
          </NonFunctionalsStyles.Card>
        </div>
      </NonFunctionalsStyles.Wrapper>
    </AnimatePresence>
  );
};

export default WebGLDisabled;
