import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';

import Heading from 'components/Heading/Heading';
// import { path } from 'services/api';
// import { useCopyStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { isDesktop } from 'u9/utils/platform';
import { fadeMotionProps } from 'utils/styles/animations';
import { colors } from 'utils/styles/theme';

import * as NonFunctionalsStyles from './../NonFunctionals.styles';
import * as Styled from './MobileOnly.styles';

interface MobileOnly {
  copy: GlobalCopy['errors']['mobileOnly'];
}

const MobileOnly = ({ copy }: MobileOnly) => {
  const { theme } = useThemeStore();
  // const { userLang } = useCopyStore();
  const bgRef = useRef(null);
  const percentage = 0;
  const adj = -0.3;

  useEffect(() => {
    const ref = bgRef.current;
    if (isDesktop()) {
      ref.addEventListener('mousemove', event => parallax(event));
    }

    return () => ref.removeEventListener('mousemove', event => parallax(event));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parallax = e => {
    const imgs = bgRef.current.children;
    const amountMovedX = (e.clientX * adj) / 500;
    const amountMovedY = (e.clientY * adj) / 500;

    imgs[0].style.transform =
      'translate(' +
      (-percentage + amountMovedX) +
      '%,' +
      (-percentage + amountMovedY) +
      '%)';
  };

  // const qrPath = () => {
  //   const _path = process.env.PUBLIC_URL;
  //   return `${_path}${userLang}`;
  // };

  return (
    <AnimatePresence>
      <Styled.WrapperExtended
        $bgUrl={theme.errors.desktopBg}
        $bgElementsUrl={theme.errors.desktopBgElements}
        $theme={theme}
        ref={bgRef}
        {...fadeMotionProps}
      >
        <div className="parallaxed" />
        <Styled.Box>
          <Heading title={copy.title} imgUrl={theme.headings?.desktop} />
          <QRCode
            bgColor="rgba(0,0,0,0)"
            fgColor={colors.white}
            size={180}
            level="M"
            value={'https://www.mirindaflavourgenerator.com/sa'}
            viewBox={'0 0 100 100'}
          />
          <div>
            <NonFunctionalsStyles.Description
              dangerouslySetInnerHTML={{ __html: copy.body1 }}
            />
            <NonFunctionalsStyles.Description
              dangerouslySetInnerHTML={{ __html: copy.body2 }}
            />
          </div>
        </Styled.Box>
      </Styled.WrapperExtended>
    </AnimatePresence>
  );
};

export default MobileOnly;
