import { motion } from 'framer-motion';
import styled from 'styled-components';

import { fullscreenWhite } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

interface WrapperProps {
  $bgUrl: string;
  $bgElementsUrl: string;
}

export const Wrapper = styled(motion.div)<WrapperProps>`
  ${fullscreenWhite()}
  z-index: ${layout.zIndex.nonFunctionals};
  background-image: url(${props => props.$bgUrl});
  background-size: cover;
  background-position: 50% 50%;

  > div {
    background-image: url(${props => props.$bgElementsUrl});
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      margin-top: 2%;
      height: 30%;
      ${mediaTablet(`
        margin-top: 5%;
        height: 30%;
      `)}
    }

    > p {
      margin-top: 2%;
    }
  }
`;
