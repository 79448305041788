import styled from 'styled-components';

import { setVh } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: ${setVh(100)};
  pointer-events: none;
  z-index: 5;
`;

export const CanvasRenderer = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export const Buttons = styled.div`
  display: flex;
  top: 0;
  left: 0;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;

  & button {
    width: 260rem;
    height: 110rem;
    background-color: blue;
    margin: 14rem 30rem;
  }
`;

export const CanvasDrawing = styled.canvas`
  position: fixed;
  width: 512;
  height: 512;
  top: -512px;
  left: -512px;
  display: none;
`;
