import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import React from 'react';

import heading from 'assets/img/errorPages/404heading.png';
import logo from 'assets/img/mirindaLogo.png';
import AppBackground from 'components/AppBackground/AppBackground';
import Button from 'components/Button/Button';
import useThemeStore from 'store/visuals';
import { ReactComponent as Icon } from 'svgs/404.svg';
import { ROUTES } from 'utils/routes';
import { fadeMotionProps } from 'utils/styles/animations';

import * as NonFunctionalsStyles from './../NonFunctionals.styles';
import * as Styled from './NotFound.styles';

const NotFound = ({ copy }) => {
  const { theme } = useThemeStore();
  const router = useRouter();

  const onClick = () => {
    router.push(ROUTES.HOME);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <AnimatePresence>
      <NonFunctionalsStyles.Wrapper {...fadeMotionProps}>
        <AppBackground />

        <div>
          <img alt="logo" src={logo} />
          <Styled.CardExtended>
            <Icon />
            <img alt={copy.title} src={heading} />
            <NonFunctionalsStyles.Description
              dangerouslySetInnerHTML={{ __html: copy.body }}
            />
            <Button
              onClick={onClick}
              label={copy.cta}
              imgUrl={theme.ctas?.modalBackHome}
            />
          </Styled.CardExtended>
        </div>
      </NonFunctionalsStyles.Wrapper>
    </AnimatePresence>
  );
};

export default NotFound;
