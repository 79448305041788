import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
`;
export const CookieBar = styled.div`
  z-index: 100;
  right: 0;
  position: fixed;
  transform: translate(85%, 23%);
  bottom: 0;
`;
