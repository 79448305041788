import gsap from 'gsap';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';

import { CanModel } from './CanModel';
import { baseScale, baseScaleIntro } from './config';

export const restoreInitialAnim = canModel => {
  canModel.rotation.z = 0;
  canModel.rotation.x = 0;
  canModel.can.rotation.y = 0;
  canModel.can.rotation.x = 0;
  canModel.can.rotation.z = 0;
  canModel.can.scale.set(baseScale, baseScale, baseScale);
  canModel.can.position.set(0, 0, 0);
  canModel.position.set(0, -10, 0);
  canModel.shouldAutorotate = false;
};

export const introCanBottomAnim = canModel => {
  if (canModel.animTL) canModel.animTL.kill();
  canModel.animTL = gsap
    .timeline({
      onStart: () => {
        canModel.shouldAutorotate = false;
      },
    })
    .to(canModel.can.rotation, {
      duration: 3,
      ease: 'linear',
      z: 0.4,
      x: -0.1,
      y: '-=10_cw',
    })
    .to(
      canModel.rotation,
      {
        duration: 3,
        ease: 'linear',
        x: -0.5,
      },
      '<'
    )
    .to(
      canModel.position,
      {
        duration: 3,
        ease: 'ease.out',
        y: -10,
        x: 0,
      },
      '>-1.5'
    );
};

export const showCanFinalAnim = (canModel: CanModel) => {
  canModel.restoreInitial();
  if (canModel.animTL) canModel.animTL.kill();

  canModel.can.scale.set(baseScale, baseScale, baseScale);

  canModel.can.position.set(0, 0, 0);
  // canModel.can.rotation.set(-0.5, 0, 0);
  canModel.position.set(0, -10, 0);

  canModel.animTL = gsap
    .timeline({
      onStart: () => {
        canModel.shouldAutorotate = false;
      },
      onComplete: () => {
        canModel.shouldAutorotate = true;
      },
    })
    .to(canModel.position, {
      duration: 1,
      ease: 'power4.out',
      y: canModel.locale === 'pk' ? -1.8 : -1.6,
      // x: -0.2,
    });
};

export const hideCanFinalAnim = canModel => {
  if (canModel.animTL) canModel.animTL.kill();
  canModel.animTL = gsap
    .timeline({
      onComplete: () => {
        canModel.updateTexture(
          { data: WebGLContainer.dictionary.canOriginal },
          false
        );
        canModel.rotation.z = 0.2;
        canModel.rotation.x = -0.2;
        canModel.rotation.y = 0;
        canModel.can.rotation.y = 2.7;
        canModel.position.set(0, -10, 0);
        canModel.shouldAutorotate = false;
      },
    })
    .to(canModel.can.position, { duration: 0.3, x: 0 })
    .to(canModel.position, { duration: 0.3, y: 0, x: 0 }, '<')
    .to(
      canModel.can.scale,
      {
        duration: 0.3,
        y: 0.01,
        x: 0.01,
        z: 0.01,
      },
      '<'
    );
};

export const introCanRotateAnim = (canModel: CanModel) => {
  if (canModel.animTL) canModel.animTL.kill();
  canModel.can.scale.set(baseScale, baseScale, baseScale);
  canModel.rotation.set(0, 0, 0.1);
  canModel.position.set(0.1, -10, 0);
  canModel.animTL = gsap
    .timeline({})
    .to(canModel.position, {
      duration: 1.6,
      ease: 'power4.inOut',
      y: canModel.locale === 'pk' ? -1.9 : -1.8,
    })
    .to(
      canModel.rotation,
      {
        duration: 1.1,
        ease: 'linear',
        x: -0.1,
      },
      '<'
    )
    .to(canModel.rotation, {
      duration: 3,
      ease: 'linear',
      y: -0.3,
    })
    .to(canModel.can.scale, {
      duration: 0.6,
      y: baseScaleIntro,
      x: baseScaleIntro,
      z: baseScaleIntro,
    })
    .to(
      canModel.can.rotation,
      {
        duration: 0.9,
        ease: 'ease.out',
        y: -Math.PI * 2,
        onStart: () => {
          canModel.animateTextures();
        },
      },
      '<'
    )
    .to(
      [canModel.arrowTop.material, canModel.arrowBottom.material],
      {
        duration: 1,
        ease: 'linear',
        opacity: 1,
      },
      '<'
    )
    .to(
      [canModel.arrows.rotation],
      {
        duration: 1,
        ease: 'ease.out',
        y: -20,
      },
      '>-0.8'
    )
    .to(
      [canModel.arrowTop.material, canModel.arrowBottom.material],
      {
        duration: 0.6,
        opacity: 0,
      },
      '>-0.8'
    )
    .to(
      canModel.rotation,
      {
        duration: 1.2,
        ease: 'ease.inOut',
        x: 0.2,
        y: -Math.PI * 2,
        onComplete: () => {
          canModel.shouldAutorotate = true;
        },
      },
      '<'
    );
};
