import { GUI } from 'lil-gui';
import {
  BlendFunction,
  BrightnessContrastEffect,
  EdgeDetectionMode,
  EffectComposer,
  EffectPass,
  PredicationMode,
  RenderPass,
  SelectiveBloomEffect,
  SMAAEffect,
  SMAAPreset,
  // eslint-disable-next-line node/no-extraneous-import
} from 'postprocessing';
import { HalfFloatType } from 'three';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { isLowPerformanceIphone } from 'u9/utils/platform';
import { parseUrlQuery } from 'u9/utils/url';

export class PostPro {
  static smaaEffect: SMAAEffect;
  static bloomPass: SelectiveBloomEffect;
  static brightnessContrastEffect: BrightnessContrastEffect;
  static bloomSelection: any[] = [];
  static pass: EffectPass;
  static setup() {
    if (WebGLContainer.finalComposer) WebGLContainer.finalComposer.dispose();
    const isLowPerformance = isLowPerformanceIphone();

    WebGLContainer.finalComposer = new EffectComposer(WebGLContainer.renderer, {
      frameBufferType: HalfFloatType,
    });
    WebGLContainer.finalComposer.addPass(
      new RenderPass(WebGLContainer.mainScene, WebGLContainer.mainCamera)
    );

    PostPro.smaaEffect = new SMAAEffect({
      preset: !isLowPerformance ? SMAAPreset.HIGH : SMAAPreset.MEDIUM,
      edgeDetectionMode: EdgeDetectionMode.COLOR,
      predicationMode: PredicationMode.DEPTH,
    });

    PostPro.smaaEffect.edgeDetectionMaterial.edgeDetectionThreshold = 0.02;
    PostPro.smaaEffect.edgeDetectionMaterial.predicationThreshold = 0.002;
    PostPro.smaaEffect.edgeDetectionMaterial.predicationScale = 1;

    PostPro.bloomPass = new SelectiveBloomEffect(
      WebGLContainer.mainScene,
      WebGLContainer.mainCamera,
      {
        blendFunction: BlendFunction.ADD,
        mipmapBlur: true,
        luminanceThreshold: 0.286,
        luminanceSmoothing: 0.762,
        intensity: 0.18,
      }
    );
    PostPro.bloomPass.inverted = true;
    PostPro.bloomPass.luminancePass.enabled = false;

    PostPro.brightnessContrastEffect = new BrightnessContrastEffect({
      blendFunction: BlendFunction.SCREEN,
    });
    PostPro.brightnessContrastEffect.uniforms.get('contrast').value = 0.712;

    if (!isLowPerformance) {
      PostPro.pass = new EffectPass(
        WebGLContainer.mainCamera,
        PostPro.smaaEffect,
        PostPro.brightnessContrastEffect,
        PostPro.bloomPass
      );
    } else {
      PostPro.pass = new EffectPass(
        WebGLContainer.mainCamera,
        PostPro.brightnessContrastEffect,
        PostPro.smaaEffect
      );
    }
    WebGLContainer.finalComposer.addPass(PostPro.pass);

    PostPro.setBloomSelection();

    if (process.env.IS_DEBUG && parseUrlQuery().debugPostPro) {
      PostPro.setupDebugger();
    }
  }

  static setBloomSelection() {
    PostPro.bloomSelection.map(child => {
      PostPro.bloomPass.selection.toggle(child);
    });
  }

  static addToBloom(target) {
    PostPro.bloomPass?.selection.toggle(target);
  }

  static setupDebugger() {
    if (!WebGLContainer.dat) {
      WebGLContainer.dat = new GUI();
    }
    const folder = WebGLContainer.dat.addFolder('PostPro-Pipe');
    folder.close();
    const params = {
      bloom: {
        intensity: PostPro.bloomPass.intensity,
        luminance: {
          filter: PostPro.bloomPass.luminancePass.enabled,
          threshold: PostPro.bloomPass.luminanceMaterial.threshold,
          smoothing: PostPro.bloomPass.luminanceMaterial.smoothing,
        },
        selection: {
          inverted: PostPro.bloomPass.inverted,
          'ignore bg': PostPro.bloomPass.ignoreBackground,
        },
        opacity: PostPro.bloomPass.blendMode.opacity.value,
        'blend mode': PostPro.bloomPass.blendMode.blendFunction,
      },
      brightnessContrast: {
        brightness:
          PostPro.brightnessContrastEffect.uniforms.get('brightness').value,
        contrast:
          PostPro.brightnessContrastEffect.uniforms.get('contrast').value,
        opacity: PostPro.brightnessContrastEffect.blendMode.opacity.value,
        'blend mode': PostPro.brightnessContrastEffect.blendMode.blendFunction,
      },
    };
    // BLOOM CONTROLS
    const bloomFolder = folder.addFolder('Bloom');
    bloomFolder.close();
    bloomFolder
      .add(params.bloom, 'intensity', 0.0, 10.0, 0.01)
      .onChange(value => {
        PostPro.bloomPass.intensity = Number(value);
      });

    const lumFolder = bloomFolder.addFolder('Luminance');
    lumFolder.close();
    lumFolder.add(params.bloom.luminance, 'filter').onChange(value => {
      PostPro.bloomPass.luminancePass.enabled = value;
    });

    lumFolder
      .add(params.bloom.luminance, 'threshold', 0.0, 1.0, 0.001)
      .onChange(value => {
        PostPro.bloomPass.luminanceMaterial.threshold = Number(value);
      });

    lumFolder
      .add(params.bloom.luminance, 'smoothing', 0.0, 1.0, 0.001)
      .onChange(value => {
        PostPro.bloomPass.luminanceMaterial.smoothing = Number(value);
      });

    const selectionFolder = bloomFolder.addFolder('Selection');
    selectionFolder.close();
    selectionFolder.add(params.bloom.selection, 'inverted').onChange(value => {
      PostPro.bloomPass.inverted = value;
    });

    selectionFolder.add(params.bloom.selection, 'ignore bg').onChange(value => {
      PostPro.bloomPass.ignoreBackground = value;
    });

    selectionFolder.open();

    bloomFolder.add(params.bloom, 'opacity', 0.0, 1.0, 0.01).onChange(value => {
      PostPro.bloomPass.blendMode.opacity.value = value;
    });

    bloomFolder
      .add(params.bloom, 'blend mode', BlendFunction)
      .onChange(value => {
        PostPro.bloomPass.blendMode.blendFunction = Number(value);
      });
    // BLOOM CONTROLS

    const brightnessContrastEffectF = folder.addFolder('Brightness & Contrast');

    brightnessContrastEffectF
      .add(params.brightnessContrast, 'brightness', -1.0, 1.0, 0.001)
      .onChange(value => {
        PostPro.brightnessContrastEffect.uniforms.get('brightness').value =
          value;
      });

    brightnessContrastEffectF
      .add(params.brightnessContrast, 'contrast', -1.0, 1.0, 0.001)
      .onChange(value => {
        PostPro.brightnessContrastEffect.uniforms.get('contrast').value = value;
      });

    brightnessContrastEffectF
      .add(params.brightnessContrast, 'opacity', 0.0, 1.0, 0.01)
      .onChange(value => {
        PostPro.brightnessContrastEffect.blendMode.opacity.value = value;
      });

    brightnessContrastEffectF
      .add(params.brightnessContrast, 'blend mode', BlendFunction)
      .onChange(value => {
        PostPro.brightnessContrastEffect.blendMode.blendFunction =
          Number(value);
      });
  }
}
