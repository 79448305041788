export const roughness = 0.52;
export const metalness = 0.99;
export const clearcoat = 1.0;
export const clearcoatRoughness = 0.15;
export const baseScale = 2.8;
export const baseScaleIntro = 2.4;

export enum MeshType {
  MainMesh = 'texture_a',
  LogoMesh = 'texture_logo',
}
