/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AnimatePresence } from 'framer-motion';
import { Router } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useWindowSize from 'u9/hooks/useWindowSize';
import {
  ALLOWED_BROWSERS,
  hasWebGl,
  isInstagram,
  isMobile,
  isSupportedBrowser,
  isTablet,
} from 'u9/utils/platform';
import { parseUrlQuery } from 'u9/utils/url';
import { ROUTES } from 'utils/routes';
import { isMobileLayout } from 'utils/styles/responsive';

import JavaScriptDisabled from './JavaScriptDisabled/JavaScriptDisabled';
import MobileOnly from './MobileOnly/MobileOnly';
import NotFound from './NotFound/NotFound';
import RotateDevice from './RotateDevice/RotateDevice';
import UnsupportedBanner from './UnsupportedBrowserBanner/UnsupportedBrowserBanner';
import WebGLDisabled from './WebGLDisabled/WebGLDisabled';

interface NonFunctionalsProps {
  copy: GlobalCopy;
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
  router: Router;
}

const NonFunctionals: React.FunctionComponent<NonFunctionalsProps> = ({
  copy,
  children,
  isBypassed = false,
  router,
}) => {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [hasNoWebGl, setNoWebGl] = useState(false);
  const [hasBanner, setHasBanner] = useState(false);

  const hasMatchingRoute = useMemo(
    () =>
      Object.values(ROUTES).includes(
        router.route as typeof ROUTES[keyof typeof ROUTES]
      ) && router.route !== ROUTES.NOT_FOUND,
    [router.route]
  );

  const windowSize = useWindowSize(true);

  const { shouldRotateDevice, setRotateDevice } = useGlobalStore();

  useEffect(() => {
    setMounted(true);

    if (process.env.IS_DEBUG && router.asPath.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted) {
      setRendered(true);

      const { isSupported, needsUpgrade, fromSocial } = isSupportedBrowser();

      if (
        (!isSupported || needsUpgrade || fromSocial) &&
        isMobileLayout() &&
        !isInstagram()
      ) {
        setHasBanner(true);
      }
      setNoWebGl(!hasWebGl());
    }
  }, [isMounted]);

  useEffect(() => {
    setRotateDevice(
      (isMobile() && windowSize.isLandscape) ||
        (isTablet() && !windowSize.isLandscape)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  const errorComponent = useMemo(() => {
    if (
      isMounted &&
      !ALLOWED_BROWSERS.desktop.length &&
      !isMobileLayout() &&
      !parseUrlQuery().debugPostPro
    ) {
      return <MobileOnly copy={copy.errors.mobileOnly} />;
    } else if (!hasMatchingRoute) {
      return <NotFound copy={copy.errors.notFound} />;
    } else if (hasNoWebGl) {
      return <WebGLDisabled copy={copy.errors.webGLDisabled} />;
    }

    return null;
  }, [isMounted, hasMatchingRoute, hasNoWebGl]);

  if (isBypassed) return <>{children}</>;

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {errorComponent || children}
      </AnimatePresence>
      {!isRendered && (
        <noscript>
          <JavaScriptDisabled copy={copy.errors.javascriptDisabled} />
        </noscript>
      )}
      {isRendered && (
        <UnsupportedBanner
          isVisible={hasBanner}
          copy={copy.errors.unsupportedBrowser}
        />
      )}
      {isRendered && (
        <RotateDevice
          isVisible={shouldRotateDevice}
          copy={copy.errors.rotateDevice}
        />
      )}
    </>
  );
};

export default React.memo(NonFunctionals);
