import create, { UseBoundStore } from 'zustand';

import { SPA_ROUTES } from 'utils/routes';

import { AiError, MARKET } from 'constants/enums';

export interface GlobalStoreType {
  currentPage: string;
  setCurrentPage: (state: string) => void;

  imageSource: 'IG' | 'LOCAL' | '';
  setImageSource: (state: 'IG' | 'LOCAL' | '') => void;

  withIG: boolean;
  setWithIG: (state: boolean) => void;

  isUserSelected: boolean;
  setIsUserSelected: (state: boolean) => void;

  shareDisabled: boolean;
  setShareDisabled: (state: boolean) => void;

  isTermsAndConditions: boolean;
  setIsTermsAndConditions: (state: boolean) => void;

  market: string;
  setMarket: (state: string) => void;

  imageUrl: string;
  setImageUrl: (state: string) => void;

  imageUrls: string[];
  setImageUrls: (state: string[]) => void;

  lastSelectedFiles: FormData;
  setLastSelectedFiles: (state: FormData) => void;

  showAiError: AiError;
  setAiError: (state: AiError) => void;

  lastSelectedUrls: string[];
  setLastSelectedUrls: (state: string[]) => void;

  allUrls: string[];
  setAllUrls: (state: string[]) => void;

  shouldRotateDevice: boolean;
  setRotateDevice: (state: boolean) => void;

  showAtLeast3ImagesError: boolean;
  setShowAtLeast3ImagesError: (state: boolean) => void;

  showAtLeast3PostsError: boolean;
  setShowAtLeast3PostsError: (state: boolean) => void;

  processingExitAnim: boolean;
  playProcessingExitAnim: (state: boolean) => void;

  processingPreEnterAnim: boolean;
  playProcessingPreEnterAnim: (state: boolean) => void;

  backgroundUpAnim: boolean;
  playBackgroundUpAnim: (state: boolean) => void;

  gifSavedModal: boolean;
  showGifSavedModal: (state: boolean) => void;

  gifWaitModal: boolean;
  showGifWaitModal: (state: boolean) => void;

  refreshCanCount: number;
  setRefreshCanCount: (state: number) => void;
}

const useGlobalStore: UseBoundStore<GlobalStoreType> = create(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    return {
      currentPage: SPA_ROUTES.LOADER,
      setCurrentPage: state => {
        set(() => ({
          currentPage: state,
        }));
      },

      isUserSelected: false,
      setIsUserSelected: state => {
        set(() => ({
          isUserSelected: state,
        }));
      },

      withIG: false,
      setWithIG: state => {
        set(() => ({
          isUserSelected: state,
        }));
      },

      isTermsAndConditions: false,
      setIsTermsAndConditions: state => {
        set(() => ({
          isTermsAndConditions: state,
        }));
      },

      imageSource: 'LOCAL',
      setImageSource: state => {
        set(() => ({
          imageSource: state,
        }));
      },
      market: MARKET.International,
      setMarket: state => {
        set(() => ({
          market: state,
        }));
      },

      lastSelectedFiles: null,
      setLastSelectedFiles: state => {
        set(() => ({
          lastSelectedFiles: state,
        }));
      },

      lastSelectedUrls: [],
      setLastSelectedUrls: state => {
        set(() => ({
          lastSelectedUrls: state,
        }));
      },

      allUrls: [],
      setAllUrls: state => {
        set(() => ({
          allUrls: state,
        }));
      },

      imageUrl: 'insta',
      setImageUrl: state => {
        set(() => ({
          imageUrl: state,
        }));
      },

      imageUrls: [],
      setImageUrls: state => {
        set(() => ({
          imageUrls: state,
        }));
      },

      shouldRotateDevice: false,
      setRotateDevice: state => {
        set(() => ({
          shouldRotateDevice: state,
        }));
      },

      shareDisabled: false,
      setShareDisabled: state => {
        set(() => ({
          shareDisabled: state,
        }));
      },

      showAtLeast3ImagesError: false,
      setShowAtLeast3ImagesError: state => {
        set(() => ({
          showAtLeast3ImagesError: state,
        }));
      },

      showAtLeast3PostsError: false,
      setShowAtLeast3PostsError: state => {
        set(() => ({
          showAtLeast3PostsError: state,
        }));
      },

      processingExitAnim: false,
      playProcessingExitAnim: state => {
        set(() => ({
          processingExitAnim: state,
        }));
      },

      processingPreEnterAnim: false,
      playProcessingPreEnterAnim: state => {
        set(() => ({
          processingPreEnterAnim: state,
        }));
      },

      backgroundUpAnim: false,
      playBackgroundUpAnim: state => {
        set(() => ({
          backgroundUpAnim: state,
        }));
      },

      gifSavedModal: false,
      showGifSavedModal: state => {
        set(() => ({
          gifSavedModal: state,
        }));
      },

      gifWaitModal: false,
      showGifWaitModal: state => {
        set(() => ({
          gifWaitModal: state,
        }));
      },

      showAiError: AiError.none,
      setAiError: state => {
        set(() => ({
          showAiError: state,
        }));
      },

      refreshCanCount: 0,
      setRefreshCanCount: state => {
        set(() => ({
          refreshCanCount: state,
        }));
      },
    };
  }
);

export default useGlobalStore;
