import axios from 'axios';

import { getMarketIsoLocaleCode } from 'utils/config';
import {
  // DEFAULT_PROCESSING_TIME_TO_90_PERCENT_IN_MS,
  MAX_SELECTED,
  MIN_SELECTED,
} from 'utils/constants';
import { SPA_ROUTES } from 'utils/routes';

import { getCaptchaID } from 'config';
import { AiError } from 'constants/enums';

export const path = () => {
  if (process.env.ENV === 'production') return process.env.FINAL_BE_URL;
  else if (process.env.ENV === 'test')
    return 'https://u9ltd-project-3278-mirind-c3bd-be-test-ojjuec5x2q-ew.a.run.app/api/';
  else if (process.env.ENV === 'staging')
    return 'https://3278-mirinda-ai-can-backend-staging.unit9.net/api/';
  else
    return 'https://u9ltd-project-3278-mirind-c3bd-be-development-ojjuec5x2q-ew.a.run.app/api/';
};

const testArray1 = [
  'https://scontent-atl3-1.cdninstagram.com/v/t51.29350-15/318850824_537553164934338_1241375436401143590_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=mZChlnQLSsIAX91zRCn&_nc_ht=scontent-atl3-1.cdninstagram.com&edm=ABbrh9MEAAAA&oh=00_AfD2l99810t9Y-QFQAuGVKU3mBuq1cMTVwvynm4eHmeKMQ&oe=64218864',
  'https://scontent-atl3-1.cdninstagram.com/v/t51.29350-15/319412054_180007578037961_2507391519531898972_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=YkUSBBYS1HYAX-i_cXQ&_nc_ht=scontent-atl3-1.cdninstagram.com&edm=ABbrh9MEAAAA&oh=00_AfCnP6ISlDwzsZAwr854vUASJttjKTJeW0AyTyD2cbmrAw&oe=6420CAF7',
  'https://scontent-atl3-2.cdninstagram.com/v/t51.29350-15/319112418_535480035165630_2993409571327749845_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=iBQSLfv05tsAX8T4n4p&_nc_ht=scontent-atl3-2.cdninstagram.com&edm=ABbrh9MEAAAA&oh=00_AfBzRqFs8buQkWPJ51rgRNdhQlirhmnzjIMXxH9GXLwDyw&oe=642047F9',
  'https://scontent-atl3-1.cdninstagram.com/v/t51.29350-15/319130908_123460917242277_4439046788615643340_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=nHnn0N01n5gAX8eHXEB&_nc_ht=scontent-atl3-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBelF43ZekZlGa9L38F_fE0N4VrkGt1MTVSVuRpM3npPw&oe=6420966C',
  'https://scontent-atl3-2.cdninstagram.com/v/t51.29350-15/318825219_511968964326897_6415755690952596725_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Vg9rw3Pl22UAX-bNyfe&_nc_ht=scontent-atl3-2.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAbQ7bjhtZbLwaxXjafSRJs78nnVlsBDPpiwHFaP2Vhag&oe=64207C01',
  'https://scontent-atl3-2.cdninstagram.com/v/t51.29350-15/319552593_541960140872584_10438273004390353_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=5R51ZY8wf4kAX9sB7je&_nc_ht=scontent-atl3-2.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCJWXQsYMSbCvn9gE-gEr_A4MeFv5oEYhlP27kOBNLumA&oe=642208CE',
];

const marketsWithSpecificTexture = ['vi', 'pk'];

const proceedToArtworkPage = (playProcessingExitAnim, setCurrentPage) => {
  playProcessingExitAnim(true);
  setTimeout(() => {
    playProcessingExitAnim(false);
    setCurrentPage(SPA_ROUTES.ARTWORK);
  }, 500);
};

export const proceedToProcessingPage = (
  playProcessingPreEnterAnim,
  setCurrentPage
) => {
  playProcessingPreEnterAnim(true);
  setTimeout(() => {
    setCurrentPage(SPA_ROUTES.PROCESSING);
    playProcessingPreEnterAnim(false);
  }, 500);
};

export const authInstagram = async (locale, setShowLoginError) => {
  // console.error('authInstagram', locale);
  const loginUrl = await API.instance.instagramAuth(locale);
  // console.error('authInstagram', loginUrl);
  if (!loginUrl) setShowLoginError(true);
  else window.open(loginUrl, '_self');
};

export const executeRecaptcha = async action => {
  try {
    return new Promise((resolve, reject) => {
      window['grecaptcha']
        .execute(getCaptchaID(), { action: action })
        .then(token => {
          resolve(token);
        })
        .catch(error => {
          reject(error);
        });
    });
  } catch (error) {
    console.log(error);
    return ' ';
  }
};

export const getInstagramPhotos = async (
  userCode: string,
  setAiError: (arg: AiError) => void,
  setCurrentPage: (arg: string) => void,
  setImageUrl: (arg: string) => void,
  setLastSelectedUrls: (arg: string[]) => void,
  setShowAtLeast3PostsError: (arg: boolean) => void,
  setAllUrls: (arg: string[]) => void,
  playProcessingExitAnim: (arg: boolean) => void,
  manualSelectionIG: boolean
) => {
  const userData = await API.instance.getInstagramPhotos(userCode);
  // console.error('instagramPhotos', userData);
  // const imagesUrls = testArray1; // DEBUG
  const imagesUrls = userData.images;

  if (imagesUrls.length < MIN_SELECTED) {
    setShowAtLeast3PostsError(true);
  } else {
    setAllUrls(imagesUrls);

    if (!manualSelectionIG) {
      await API.instance.generateFromLinks(
        imagesUrls.slice(0, MAX_SELECTED),
        setAiError,
        setCurrentPage,
        setImageUrl,
        playProcessingExitAnim,
        setLastSelectedUrls
      );
    }
  }
};

export class API {
  private static _instance;
  locale = 'en';

  static get instance() {
    if (!API._instance) {
      API._instance = new API();
    }
    return API._instance;
  }

  setLocale(locale) {
    this.locale = locale;
  }

  async instagramAuth(locale) {
    const recaptcha_token = await executeRecaptcha('submit');
    const marketData =
      locale !== 'en'
        ? `users/ig/login-url?market=${getMarketIsoLocaleCode(
            this.locale
          )}&recaptcha_token=${recaptcha_token}`
        : `users/ig/login-url?recaptcha_token=${recaptcha_token}`;

    const { data } = await axios.get(path() + marketData);
    console.log(data);
    return await data.url;
  }

  async getInstagramPhotos(code) {
    const recaptcha_token = await executeRecaptcha('submit');
    const marketData =
      this.locale !== 'en'
        ? `users/ig/media?market=${getMarketIsoLocaleCode(this.locale)}`
        : 'users/ig/media';

    const { data } = await axios.post(path() + marketData, {
      code,
      recaptchaToken: recaptcha_token,
    });
    console.log(data);
    return await data;
  }

  async generateFromLinks(
    array: [],
    setAiError,
    setCurrentPage,
    setImageUrl,
    playProcessingExitAnim,
    setLastSelectedUrls?
  ) {
    if (setLastSelectedUrls) setLastSelectedUrls(array);

    const marketData = marketsWithSpecificTexture.includes(this.locale)
      ? `cans/generate-from-links?ai=collage&market=${this.locale}`
      : 'cans/generate-from-links?ai=collage';

    const recaptcha_token = await executeRecaptcha('submit');

    const { status, data } = await axios.post(path() + marketData, {
      selectedImagesUrls: array.map(image => image['url']),
      flavour: 'test',
      imageDescriptions: array.map(image => image['caption']),
      market: marketsWithSpecificTexture.includes(this.locale)
        ? this.locale
        : '',
      recaptchaToken: recaptcha_token,
    });

    if (status === 500) setAiError = AiError.oops;
    if (status === 503) setAiError = AiError.capacity;

    setImageUrl(data.textureUrl);
    proceedToArtworkPage(playProcessingExitAnim, setCurrentPage);

    return await data.textureUrl;
  }

  async generateFromImages(
    formData: any,
    setAiError,
    setCurrentPage,
    setImageUrl,
    playProcessingExitAnim
  ) {
    const recaptcha_token = await executeRecaptcha('submit');

    const marketData = marketsWithSpecificTexture.includes(this.locale)
      ? `cans/upload-and-generate?ai=collage&market=${this.locale}`
      : 'cans/upload-and-generate?ai=collage';

    formData.append(
      'market',
      marketsWithSpecificTexture.includes(this.locale) ? this.locale : ''
    );

    if (formData.has('market')) {
      // If 'market' already exists, update its value
      formData.set(
        'market',
        marketsWithSpecificTexture.includes(this.locale) ? this.locale : ''
      );
    } else {
      // If 'market' doesn't exist, append it to formData
      formData.append(
        'market',
        marketsWithSpecificTexture.includes(this.locale) ? this.locale : ''
      );
    }

    if (formData.has('recaptcha_token')) {
      // If 'recaptcha_token' already exists, update its value
      formData.set('recaptcha_token', recaptcha_token);
    } else {
      // If 'recaptcha_token' doesn't exist, append it to formData
      formData.append('recaptcha_token', recaptcha_token);
    }

    const { data, status } = await axios.post(
      path() + marketData,
      // path() + 'cans/upload-and-generate?ai=collage',
      formData
    );

    if (status === 500) setAiError = AiError.oops;
    if (status === 503) setAiError = AiError.capacity;

    setImageUrl(data.textureUrl);

    proceedToArtworkPage(playProcessingExitAnim, setCurrentPage);

    // DEBUG
    // setTimeout(
    //   () => proceedToArtworkPage(playProcessingExitAnim, setCurrentPage),
    //   DEFAULT_PROCESSING_TIME_TO_90_PERCENT_IN_MS + 1000
    // );

    return await data.textureUrl;
  }

  async generateFromLinksTest() {
    const recaptcha_token = await executeRecaptcha('submit');
    const { data } = await axios.post(
      path() + 'cans/generate-from-links?ai=collage',
      {
        selectedImagesUrls: testArray1,
        imageDescriptions: [' '],
        market: marketsWithSpecificTexture.includes(this.locale)
          ? this.locale
          : '',
        flavour: 'test',
        recaptchaToken: recaptcha_token,
      }
    );
    console.log(data);
    return await data.textureUrl;
  }

  async uploadSharable(formData: any) {
    const recaptcha_token = await executeRecaptcha('submit');
    formData.append(
      'market',
      marketsWithSpecificTexture.includes(this.locale) ? this.locale : ''
    );
    formData.append('recaptcha_token', recaptcha_token);

    const { data } = await axios.post(
      `${path()}cans/upload-shareable`,
      formData
    );

    return await data.url;
  }
}
