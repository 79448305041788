import styled, { css } from 'styled-components';

import { buttonTap } from 'utils/styles/animations';
import { easeInOutCirc, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

interface Props {
  $whiteBg?: boolean;
  $icon?: boolean;
  $vertical?: boolean;
  $animate: boolean;
}

export const Button = styled.button<Props>`
  padding: 20rem;
  color: ${props =>
    props.$whiteBg ? ({ theme }) => theme.global.colors.font : colors.white};
  background-color: ${props =>
    props.$whiteBg ? colors.white : ({ theme }) => theme.global.colors.font};
  border-radius: 5px;
  width: ${props => (props.$whiteBg ? '50%' : '70%')};
  height: 180rem;
  margin-bottom: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme: { locale } }) => setTypography('button', locale)}
  font-size: ${({ theme: { locale } }) => locale === 'pk' && '60rem'};

  > span {
    padding-top: ${({ theme: { locale } }) => locale !== 'pk' && '15rem'};
  }

  > img {
    height: 60rem;
    margin-bottom: 1%;
  }

  > svg {
    ${({ theme }) => `color: ${theme.global.colors.font};`}
  }

  ${({ $icon }) =>
    $icon &&
    css`
      padding: 20rem 0;
      width: 90%;
      height: 180rem;

      > img {
        height: 53rem;
        margin-bottom: 0.2%;
      }

      > svg {
        height: 65rem;
        width: 65rem;
        margin-right: 20rem;
      }
    `};

  ${({ $icon, $vertical }) =>
    $icon &&
    $vertical &&
    css`
      flex-direction: column;

      > svg {
        height: auto;
        width: 45%;
        margin: 5% auto;
      }
    `};

  ${({ $animate }) =>
    $animate &&
    css`
      animation: 0.4s 1 ${buttonTap} ${easeInOutCirc};
    `};
`;
