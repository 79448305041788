import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.black};
  padding: 30rem;
  z-index: ${layout.zIndex.nonFunctionals};

  svg {
    height: 100rem;
  }

  p {
    width: 90%;
    padding: 5rem;
    text-align: center;
    ${({ theme: { locale } }) => setTypography('bodyErrorPage', locale)}
    font-size: ${({ theme: { locale } }) => locale === 'vi' && '45rem'};
  }
`;
