export const IS_DEV =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

export const PROD_PATH = '';
export const market = '';

export const getCaptchaID = () => process.env.RECAPTCHA_KEY;

// WSP 6LekZ_0kAAAAAJ4HQURYxGveNsTTyu42gz9BhRrb
// UNI 6LcPfO8lAAAAAJ1wZ-MddiD6WyP5Q62zNe52qSfP
