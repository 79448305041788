import styled, { css } from 'styled-components';

import {
  floatAnimation,
  floatSpecialAnimation,
  floatUpAnimation,
  floatUpFasterAnimation,
  floatUpNewBigBallAnimation,
  floatUpNewElementsAnimation,
  rotateAroundCanGlassesAnimation,
  rotateAroundCanHash0Animation,
  rotateAroundCanHash1Animation,
  rotateAroundCanHashBehindCanAnimation,
  rotateAroundCanToungeAnimation,
  setVh,
} from 'utils/styles/mixins';

export const Wrapper = styled.div<{
  $centerLogo: boolean;
  $processingIntroAnim: boolean;
  $shrinkExpandAnims: boolean;
  $rotateIconsAroundCanAnim: boolean;
  $rotateHashAroundCanAnim: boolean;
  $hashAroundCanExitAnim: boolean;
  $backgroundUpAnim: boolean;
  $lowerLogoAnim: boolean;
  $regenerateLogoAnim: boolean;
  $theme: any;
  $locale: string;
}>`
  min-height: ${setVh(100)};
  width: 100%;
  top: 0;
  position: fixed;
  background-color: ${props => props.$theme?.colors?.bg};

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  > img:first-of-type {
    height: 130%;
    width: auto;
    opacity: 25%;
  }

  > img:nth-of-type(2) {
    height: 88%;
    width: auto;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 60%;

    // Performance heavy layout shift
    transition: transform 0.5s ease-in-out;

    ${({ $regenerateLogoAnim }) =>
      $regenerateLogoAnim &&
      css`
        transform: translate(-50%, -12.5%) scale(1);
      `};
    ${({ $lowerLogoAnim }) =>
      $lowerLogoAnim &&
      css`
        transform: translate(-50%, -30%) scale(1);
      `};

    ${({ $centerLogo }) =>
      $centerLogo &&
      css`
        transform: translate(-50%, -75%) scale(0.75);
      `};
  }

  > .landing {
    height: ${setVh(100)};
    width: 100%;
    position: absolute;

    > img {
      position: absolute;
      width: 100rem;
      height: auto;
    }

    .ball0 {
      bottom: ${setVh(-35)};
      right: -50vw;
      width: 120vw;
      transform: rotate(260deg) scaleX(-1);
      ${floatSpecialAnimation('rotate(260deg) scaleX(-1)', 5)}

      ${({ $locale }) =>
        $locale === 'vi' &&
        css`
          right: -30vw;
          transform: '';
          ${floatSpecialAnimation('', 5)}
        `};

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpNewBigBallAnimation('rotate(260deg) scaleX(-1)', 2.2, 0.3)};
        `};

      ${({ $locale, $backgroundUpAnim }) =>
        $locale === 'vi' &&
        $backgroundUpAnim &&
        css`
          ${floatUpNewBigBallAnimation('', 2.2, 0.3)};
        `};
    }

    .ball1 {
      top: ${setVh(-30)};
      left: -15vw;
      width: 70vw;
      transform: rotate(10deg) scaleX(-1);
      ${floatSpecialAnimation('rotate(10deg) scaleX(-1)', 10)}
      z-index: 3;

      ${({ $locale }) =>
        $locale === 'vi' &&
        css`
          top: ${setVh(-25)};
        `};

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpFasterAnimation('rotate(10deg) scaleX(-1)', 2, 0.5)};
          z-index: 10;
        `};
    }

    .emojiGlasses,
    .emojiTongue,
    .thumbUp,
    .music,
    .hash0,
    .hash1,
    .hash2,
    .hash3,
    .hash4,
    .hash5 {
      offset-rotate: 0deg;
    }

    .emojiGlasses {
      bottom: ${setVh(25)};
      left: -18vw;
      width: 50vw;
      offset-path: path(
        'M 99.409 114.921 A 40 20 -10 0 0 464.153 37.595 A 40 20 -10 0 0 99.197 112.594'
      );
      ${floatSpecialAnimation('', 4, true)}

      ${({ $rotateIconsAroundCanAnim }) =>
        $rotateIconsAroundCanAnim &&
        css`
          ${rotateAroundCanGlassesAnimation(2.9, 0.1)};
        `};

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpAnimation('', 2.4, 0.1)};
        `};
    }

    .emojiTongue {
      top: ${setVh(17)};
      right: -15vw;
      width: 50vw;
      transform: rotate(30deg);
      offset-path: path(
        'M 80.721 104.437 C -39.012 115.037 -262.195 65.71 -291.202 53.194 C -47.098 71.371 -68.931 76.223 80.86 104.459'
      );
      ${floatSpecialAnimation('rotate(30deg)', 3, false)}

      ${({ $rotateIconsAroundCanAnim }) =>
        $rotateIconsAroundCanAnim &&
        css`
          ${rotateAroundCanToungeAnimation(2.9, 0.1)};
        `};

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpFasterAnimation('rotate(30deg)', 2.5, 0)};
        `};
    }

    .citron {
      top: ${setVh(26)};
      left: 5vw;
      width: 36vw;
      ${floatSpecialAnimation('', 7, true)}

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpFasterAnimation('', 2.2, 0.3)};
        `};
    }

    .thumbUp {
      top: ${setVh(-5)};
      left: 0vw;
      width: 25vw;
      transform: rotate(-45deg);
      offset-path: path(
        'M 71.342 201.002 C -125.591 -32.721 485.5245 -66.663 408.021 251.658 C 377.923 298.387 92.983 247.891 74.228 202.445'
      );
      ${floatSpecialAnimation('rotate(-45deg)', 2.5, true)}

      ${({ $rotateIconsAroundCanAnim }) =>
        $rotateIconsAroundCanAnim &&
        css`
          ${rotateAroundCanGlassesAnimation(3, 0)};
        `};

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpFasterAnimation('rotate(-45deg)', 2.4, 0.1)};
        `};
    }

    .music {
      bottom: ${setVh(40)};
      right: 8vw;
      width: 25vw;
      transform: rotate(45deg);
      offset-path: path(
        'M 80.721 104.437 C 83.091 171.641 -264.62 134.444 -283.219 57.624 C -284.028 -0.597 87.135 63.284 80.86 104.459'
      );
      ${floatSpecialAnimation('rotate(45deg)', 3, true)}

      ${({ $rotateIconsAroundCanAnim }) =>
        $rotateIconsAroundCanAnim &&
        css`
          ${rotateAroundCanToungeAnimation(3, 0)};
        `};

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpAnimation('rotate(45deg)', 2.5, 0)};
        `};
    }

    .bubbles0 {
      top: ${setVh(45)};
      left: 30vw;
      width: 25vw;
      transform: rotate(-110deg);
      ${floatSpecialAnimation('rotate(-110deg)', 15, true)}

      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpAnimation('rotate(-110deg)', 2.4, 0.1)};
        `};
    }

    .bubbles1 {
      bottom: ${setVh(5)};
      left: 20vw;
      width: 70vw;
      transform: scaleX(-1);

      ${floatSpecialAnimation('scaleX(-1)', 15, true)}
      ${({ $backgroundUpAnim }) =>
        $backgroundUpAnim &&
        css`
          ${floatUpAnimation('scaleX(-1)', 2.3, 0.2)};
        `};
    }

    .additionalBubbles {
      > img {
        width: 40vw;
        position: absolute;
        height: auto;
        opacity: 0;

        ${({ $backgroundUpAnim }) =>
          $backgroundUpAnim &&
          css`
            ${floatUpNewElementsAnimation('', 2.35, 0.15)};
          `};
      }

      .bubblesA0 {
        top: 0;
        left: -30vw;
        width: 70vw;
      }

      .bubblesA1 {
        bottom: ${setVh(55)};
        right: 10vw;
        width: 40vw;
        transform: rotate(150deg);
      }

      .bubblesA2 {
        bottom: ${setVh(35)};
        left: 10vw;
        width: 50vw;
        transform: rotate(50deg);
      }
    }

    .hashWrapper {
      height: ${setVh(100)};
      width: 100%;
      position: absolute;
      display: none;

      ${({ $rotateHashAroundCanAnim, $hashAroundCanExitAnim }) =>
        ($rotateHashAroundCanAnim || $hashAroundCanExitAnim) &&
        css`
          display: block;
        `};

      > img {
        position: absolute;
        width: 25vw;
        height: auto;
        left: 40vw;
        opacity: 0;
        z-index: 3;
      }

      .hash0 {
        bottom: ${setVh(60)};
        left: 34vw;
        offset-path: path(
          'M -32.2443 134.224 C -274.5809 172.176 491.1093 164.771 63.4634 128.671'
        );

        ${({ $rotateHashAroundCanAnim }) =>
          $rotateHashAroundCanAnim &&
          css`
            ${rotateAroundCanHash0Animation(2, 0)};
          `};
      }

      .hash1 {
        width: 30vw;
        bottom: ${setVh(40)};
        offset-path: path(
          'M -33.945 161.068 C -26.54 178.656 439.983 157.366 68.801 88.868'
        );

        ${({ $rotateHashAroundCanAnim }) =>
          $rotateHashAroundCanAnim &&
          css`
            ${rotateAroundCanHash1Animation(1.9, 0.1)};
          `};
      }

      .hash2 {
        width: 20vw;
        bottom: ${setVh(65)};
        offset-path: path(
          'M -19.4845 50.394 C 11.5318 139.013 316.3034 94.703 52.6624 71.585'
        );

        ${({ $rotateHashAroundCanAnim }) =>
          $rotateHashAroundCanAnim &&
          css`
            ${rotateAroundCanHash1Animation(1.9, 0.1)};
          `};
      }

      .hash3 {
        width: 27vw;
        left: 45vw;
        bottom: ${setVh(50)};
        offset-path: path(
          'M 51.499 98.963 C 65.17 99.646 339.255 95.545 38.078 77.2026'
        );

        ${({ $rotateHashAroundCanAnim }) =>
          $rotateHashAroundCanAnim &&
          css`
            ${rotateAroundCanHash1Animation(1.5, 0.5)};
          `};
      }

      .hash4 {
        width: 18vw;
        left: 30vw;
        bottom: ${setVh(66)};
        /* offset-path: path(
          'M 155.06 67.533 C 153.693 67.533 -14.449 76.419 6.056 77.102'
        ); */
        offset-path: path(
          'M 182.682 52.9704 C 182.682 52.5888 161.2152 46.4094 67.7923 38.853'
        );

        ${({ $rotateHashAroundCanAnim }) =>
          $rotateHashAroundCanAnim &&
          css`
            ${rotateAroundCanHashBehindCanAnimation(1.5, 0.5)};
          `};
      }

      .hash5 {
        width: 28vw;
        bottom: ${setVh(40)};
        left: 25vw;
        offset-path: path(
          'M 223.1691 135.954 C 223.5996 136.0653 247.8115 124.9878 72.2411 93.3114'
        );

        ${({ $rotateHashAroundCanAnim }) =>
          $rotateHashAroundCanAnim &&
          css`
            ${rotateAroundCanHashBehindCanAnimation(1.1, 0.9)};
          `};
      }

      .hash1,
      .hash2,
      .hash3,
      .hash4,
      .hash5 {
        ${({ $hashAroundCanExitAnim }) =>
          $hashAroundCanExitAnim &&
          css`
            opacity: 1;
            z-index: 10;
            transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
          `};
      }

      .hash0 {
        ${({ $hashAroundCanExitAnim }) =>
          $hashAroundCanExitAnim &&
          css`
            transform: translate(-50%, 0%) scale(0);
            transition-delay: 0.2s;
            transition-duration: 0.4s;
            z-index: 3;
          `};
      }

      .hash1 {
        ${({ $hashAroundCanExitAnim }) =>
          $hashAroundCanExitAnim &&
          css`
            transform: translate(70%, -50%) scale(0);
          `};
      }

      .hash2 {
        ${({ $hashAroundCanExitAnim }) =>
          $hashAroundCanExitAnim &&
          css`
            transform: translate(70%, 100%) scale(0);
          `};
      }

      .hash3 {
        ${({ $hashAroundCanExitAnim }) =>
          $hashAroundCanExitAnim &&
          css`
            transform: translate(-20%, 0%) scale(0);
            transition-delay: 0.1s;
            transition-duration: 0.4s;
          `};
      }

      .hash4 {
        ${({ $hashAroundCanExitAnim }) =>
          $hashAroundCanExitAnim &&
          css`
            transform: translate(-150%, 130%) scale(0);
          `};
      }

      .hash5 {
        ${({ $hashAroundCanExitAnim }) =>
          $hashAroundCanExitAnim &&
          css`
            transform: translate(-150%, -30%) scale(0);
            transition-delay: 0.1s;
            z-index: 3;
          `};
      }
    }
  }

  > .artwork {
    height: ${setVh(100)};
    width: 100%;
    position: absolute;

    > img,
    > div img {
      position: absolute;
      width: 100rem;
      height: auto;
    }

    .ball0 {
      bottom: -50vw;
      right: -85vw;
      width: 120vw;
      ${floatAnimation('rotate(275deg) scaleX(-1)', 10)}
    }

    .ball1 {
      top: -150vw;
      left: -45vw;
      width: 80vw;
      ${floatAnimation('rotate(0deg) scaleX(-1) translateY(60vw)', 9)}
    }

    .emojiTongue {
      top: ${setVh(-9)};
      right: -15vw;
      width: 45vw;
      ${floatAnimation('rotate(30deg)', 5)}
    }

    .emojiHearts {
      top: ${setVh(30)};
      left: -15vw;
      width: 45vw;
      ${floatAnimation('rotate(-20deg) scaleX(-1)', 6)}
    }

    .citron0 {
      top: ${setVh(-15)};
      left: 5vw;
      width: 35vw;
      ${floatAnimation('', 7, true)}
    }

    .citron1 {
      top: ${setVh(45)};
      right: 5vw;
      width: 35vw;
      ${floatAnimation('scaleX(-1)', 5, true)}
    }

    .bubbles0 {
      top: ${setVh(10)};
      left: -25vw;
      width: 55vw;
      ${floatAnimation('', 11, true)}
    }

    .bubbles1 {
      top: ${setVh(37)};
      left: 23vw;
      width: 60vw;
      ${floatAnimation('scaleX(-1)', 9)}
    }

    .bubbles2 {
      top: ${setVh(-5)};
      right: -15vw;
      width: 55vw;
      ${floatAnimation('rotate(90deg)', 10)}
    }

    .stars0 {
      top: ${setVh(20)};
      right: -5vw;
      width: 40vw;
      ${floatAnimation('rotate(50deg)', 5, true)}
    }

    .stars1 {
      top: ${setVh(3)};
      left: -3vw;
      width: 35vw;
      ${floatAnimation('rotate(-50deg)', 7, false)}
    }

    .expand0 {
      height: ${setVh(100)};
      width: 100%;
      position: absolute;
      transform: scale(0);

      ${({ $processingIntroAnim }) =>
        $processingIntroAnim &&
        css`
          transform: scale(1);
          transition: transform 0.7s cubic-bezier(0.34, 1.56, 0.64, 1);
        `};

      ${({ $shrinkExpandAnims }) =>
        $shrinkExpandAnims &&
        css`
          transform: scale(0);
          transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
        `};
    }

    .expand1 {
      height: ${setVh(100)};
      width: 100%;
      position: absolute;
      transform: scale(0);

      ${({ $processingIntroAnim }) =>
        $processingIntroAnim &&
        css`
          transform: scale(1);
          transition: transform 1.4s cubic-bezier(0.33, 1, 0.68, 1) 0.3s;
        `};

      ${({ $shrinkExpandAnims }) =>
        $shrinkExpandAnims &&
        css`
          transform: scale(0);
          transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
        `};
    }
  }
`;
