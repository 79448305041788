import { AnimatePresence } from 'framer-motion';
import React from 'react';

import heading from 'assets/img/errorPages/modalNoJS_en.png';
import logo from 'assets/img/mirindaLogo.png';
import AppBackground from 'components/AppBackground/AppBackground';
import { GlobalCopy } from 'store/copy.types';
import { ReactComponent as Icon } from 'svgs/noJS.svg';
import { fadeMotionProps } from 'utils/styles/animations';

import * as NonFunctionalsStyles from './../NonFunctionals.styles';
import * as Styled from './JavaScriptDisabled.styles';

interface JavascriptDisabled {
  copy: GlobalCopy['errors']['javascriptDisabled'];
}

const JavascriptDisabled = ({ copy }: JavascriptDisabled) => {
  return (
    <AnimatePresence>
      <Styled.WrapperExtended {...fadeMotionProps}>
        <AppBackground />
        <div>
          <img alt="logo" src={logo} />
          <NonFunctionalsStyles.Card>
            <Icon />
            <img alt={copy.title} src={heading} />
            <NonFunctionalsStyles.Description
              dangerouslySetInnerHTML={{ __html: copy.body2 }}
            />
            <NonFunctionalsStyles.Description
              dangerouslySetInnerHTML={{ __html: copy.body1 }}
            />
          </NonFunctionalsStyles.Card>
        </div>
      </Styled.WrapperExtended>
    </AnimatePresence>
  );
};

export default JavascriptDisabled;
