const getShareStructurePath = () => {
  if (process.env.ENV === 'production') return process.env.FINAL_S3_URL;
  else if (process.env.ENV === 'test')
    return 'https://storage.googleapis.com/u9ltd-project-3278-mirind-c3bd-test-be-uploads/user_shares/';
  else if (process.env.ENV === 'staging')
    return 'https://u9ltd-project-3278-mirind-c3bd-staging-be-uploads.s3.amazonaws.com/user_shares/';
  else
    return 'https://storage.googleapis.com/u9ltd-project-3278-mirind-c3bd-development-be-uploads/user_shares/';
};

export const getIdOfShare = (url: string): string | null =>
  (url && url.split('/').slice(-2, -1)[0]) || null;

export const getFinalFullPath = () => {
  if (process.env.ENV === 'production') return process.env.FINAL_URL;
  else if (process.env.ENV === 'test')
    return 'https://storage.googleapis.com/u9ltd-project-3278-mirind-c3bd-test-be-uploads/user_shares/';
  else if (process.env.ENV === 'staging')
    return 'https://u9ltd-project-3278-mirind-c3bd-staging-be-uploads.s3.amazonaws.com/user_shares/';
  else
    return 'https://storage.googleapis.com/u9ltd-project-3278-mirind-c3bd-development-be-uploads/user_shares/';
};

export const generateLinkToShare = (ogImage: string, imageID: string) => {
  if (imageID) return `${getShareStructurePath()}${imageID}/can.jpg`;
  else return ogImage;
};

// stage
// https://u9ltd-project-3278-mirind-c3bd-staging-be-uploads.s3.amazonaws.com/user_shares/952eeedafd754293a317cb2ec1b512b3_1685048197641423/can.jpg
