import styled from 'styled-components';

import { Card } from '../NonFunctionals.styles';

export const CardExtended = styled(Card)`
  height: 40% !important;
  min-height: 850rem !important;

  > img {
    height: 100rem;
    width: auto !important;
  }
`;
