import React, { useEffect } from 'react';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
// import { API } from 'services/api';
import { API } from 'services/api';
import { exportImage } from 'services/sharable.service';
import { useCopyStore, useGlobalStore } from 'store';
import { ReactComponent as IconDownload } from 'svgs/download.svg';
import { ReactComponent as IconShare } from 'svgs/share.svg';
import GTM from 'u9/utils/gtm';
import { isAndroid, isSocialBrowser } from 'u9/utils/platform';

import { getIdOfShare } from './Share.utils';

import * as Styled from './Share.styles';

export interface ShareProps {
  copy: any;
  handleGif: any;
  gifSaved: boolean;
}

const Share: React.FC<ShareProps> = ({ copy, handleGif, gifSaved }) => {
  const { showGifSavedModal, showGifWaitModal, shareDisabled } =
    useGlobalStore();
  const { userLang } = useCopyStore();

  const getPath = () => window.location.origin + window.location.pathname;

  useEffect(() => {
    if (gifSaved) {
      showGifWaitModal(false);
      setTimeout(() => showGifSavedModal(true), 1000);
    }
  }, [gifSaved, showGifSavedModal, showGifWaitModal]);

  useEffect(() => {
    WebGLContainer.eventManager.on('export_shareable_ready_to_draw', () => {
      exportImage(userLang, async (path: string) => {
        const img = await fetch(path);
        const blob = await img.blob();
        const file = new File([blob], 'can.jpg', {
          type: 'image/jpeg',
        });

        let urlToShare = `${getPath()}share_${userLang}.jpg`;
        try {
          const formData = new FormData();
          formData.append('shareable', file);

          const sharableURL = await API.instance.uploadSharable(formData);

          console.log(`${getPath()}?share=${getIdOfShare(sharableURL)}`);
          console.log(`sharableURL: ${sharableURL}`);
          urlToShare = getIdOfShare(sharableURL);
        } catch {
          urlToShare = `${getPath()}share_${userLang}.jpg`;
        }

        if (canShare()) {
          const shareData = isAndroid()
            ? {
                title: copy.title,
                text: copy.title,
                url: `${getPath()}?share=${urlToShare}`,
                // files: [file],
              }
            : {
                title: copy.title,
                text: copy.title,
                url: `${getPath()}?share=${urlToShare}`,

                // title: copy.title,
                // text: copy.title,
                // // text: `${copy.title}\n${
                // //   copy.text
                // // }\n\n${getPath()}?share=${urlToShare}`,
                // url: `${getPath()}?share=${urlToShare}`,
                // // files: [file],
              };
          await navigator
            .share(shareData)
            .then(() => {
              console.log('Share was successful.');
            })
            .catch(error => {
              console.log('Sharing failed', error);
            });
        } else {
          console.log("Your system doesn't support sharing files.");
        }

        WebGLContainer.eventManager.trigger('export_shareable_complete');
        showGifWaitModal(false);
      });
    });

    // return () => {
    //   WebGLContainer.eventManager.off('export_shareable_ready_to_draw');
    //   WebGLContainer.eventManager.off('export_shareable_complete');
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copy.text, copy.title, userLang]);

  const handleShare = async () => {
    GTM.trackEvent('Your AI flavour', 'Click', 'Share Can');
    showGifWaitModal(true);
    WebGLContainer.eventManager.trigger('export_shareable_start');
  };

  const handleGifGeneration = () => {
    GTM.trackEvent('Your AI flavour', 'Click', 'Download Can');

    handleGif();
    showGifWaitModal(true);
  };

  return (
    <Styled.Wrapper>
      <section>
        <button disabled={shareDisabled} onClick={handleGifGeneration}>
          <IconDownload />
        </button>
        {!isSocialBrowser() && (
          <button disabled={shareDisabled} onClick={handleShare}>
            <IconShare />
          </button>
        )}
      </section>
    </Styled.Wrapper>
  );
};

export default Share;

export const canShare = (): boolean => {
  // Check if the navigator.share method exists
  if (navigator.share) {
    return true;
  }

  // Check if the navigator.canShare method exists
  if (navigator.canShare) {
    // Check for iOS
    if (navigator.canShare({})) {
      return true;
    }

    // Check for Android
    if (navigator.canShare({ title: '', text: '', url: '' })) {
      return true;
    }
  }

  // Share not supported
  return false;
};
