import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import { GlobalCopy } from 'store/copy.types';
import { ReactComponent as SvgClose } from 'svgs/close.svg';
import { fadeMotionProps, getBannerMotionProps } from 'utils/styles/animations';

import * as Styled from './UnsupportedBrowserBanner.styles';

export interface UnsupportedBannerProps {
  onTextClick?: () => void;
  isVisible?: boolean;
  copy: GlobalCopy['errors']['unsupportedBrowser'];
}

const defaultProps: Partial<UnsupportedBannerProps> = {
  isVisible: false,
};

const UnsupportedBanner = ({
  onTextClick,
  isVisible,
  copy,
}: UnsupportedBannerProps) => {
  const [isOpen, setOpen] = useState(true);

  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {isVisible && isOpen && (
        <Styled.Wrapper {...getBannerMotionProps('top')} {...fadeMotionProps}>
          <p
            onClick={onTextClick}
            dangerouslySetInnerHTML={{ __html: copy.body.banner }}
          />
          <SvgClose onClick={handleCloseClick} />
        </Styled.Wrapper>
      )}
    </AnimatePresence>
  );
};

UnsupportedBanner.defaultProps = defaultProps;

export default UnsupportedBanner;
