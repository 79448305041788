import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';

import Heading from 'components/Heading/Heading';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { ReactComponent as Icon } from 'svgs/rotateDevice.svg';
import { fadeMotionProps } from 'utils/styles/animations';

import * as Styled from './RotateDevice.styles';

export interface RotateDeviceProps {
  isVisible?: boolean;
  copy: GlobalCopy['errors']['rotateDevice'];
}

const defaultProps: Partial<RotateDeviceProps> = {
  isVisible: false,
};

const RotateDevice = ({ isVisible, copy }: RotateDeviceProps) => {
  const { theme } = useThemeStore();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <Styled.Wrapper
          $bgUrl={theme.errors.rotateBg}
          $bgElementsUrl={theme.errors.rotateBgElements}
          {...fadeMotionProps}
        >
          <div>
            <Icon />
            <Heading title={copy.title} imgUrl={theme.headings?.rotate} />
          </div>
        </Styled.Wrapper>
      )}
    </AnimatePresence>
  );
};

RotateDevice.defaultProps = defaultProps;

export default RotateDevice;
