import SharableBgEn from 'assets/img/sharable/sharable_bg_en.jpg';
import SharableBgMx from 'assets/img/sharable/sharable_bg_mx.jpg';
import SharableBgEs from 'assets/img/sharable/sharable_bg_mx.jpg';
import SharableBgPk from 'assets/img/sharable/sharable_bg_pk.jpg';
import SharableBgVi from 'assets/img/sharable/sharable_bg_vi.jpg';
import SharableLinesEn from 'assets/img/sharable/sharable_lines_en.png';
import SharableLinesMx from 'assets/img/sharable/sharable_lines_mx.png';
import SharableLinesEs from 'assets/img/sharable/sharable_lines_mx.png';
import SharableLinesPk from 'assets/img/sharable/sharable_lines_pk.png';
import SharableLinesVi from 'assets/img/sharable/sharable_lines_vi.png';
import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';

const layersDictionaty = {
  sharableBg_en: SharableBgEn,
  sharableBg_mx: SharableBgMx,
  sharableBg_es: SharableBgEs,
  sharableBg_vi: SharableBgVi,
  sharableBg_pk: SharableBgPk,
  sharableLines_en: SharableLinesEn,
  sharableLines_mx: SharableLinesMx,
  sharableLines_es: SharableLinesEs,
  sharableLines_vi: SharableLinesVi,
  sharableLines_pk: SharableLinesPk,
};

export const exportImage = async (
  locale: string,
  onComplete: (path: string) => void
) => {
  const layer1 = new Image();
  const layer2 = new Image();
  const layer3 = new Image();
  const srcLoaded = {
    layer1: false,
    layer2: false,
    layer3: false,
  };
  layer1.onload = () => {
    srcLoaded.layer1 = true;
    draw();
  };
  layer2.onload = () => {
    srcLoaded.layer2 = true;
    draw();
  };
  layer3.onload = async () => {
    srcLoaded.layer3 = true;
    draw();
  };
  layer1.src = layersDictionaty[`sharableBg_${locale}`];
  layer2.src = layersDictionaty[`sharableLines_${locale}`];
  const img = await fetch(
    WebGLContainer.renderer.domElement.toDataURL('image/png')
  );
  const blob = await img.blob();
  const url = URL.createObjectURL(blob);
  layer3.src = url;

  const sizes = {
    w: 1200,
    h: 630,
  };
  const canvasComp = document.createElement('canvas');
  canvasComp.width = sizes.w;
  canvasComp.height = sizes.h;
  canvasComp.style.position = 'fixed';
  canvasComp.style.top = '0';
  canvasComp.style.left = '0';

  const ctx = canvasComp.getContext('2d');

  async function draw() {
    if (srcLoaded.layer1 && srcLoaded.layer2 && srcLoaded.layer3) {
      ctx.clearRect(0, 0, canvasComp.width, canvasComp.height);
      ctx.drawImage(layer1, 0, 0, sizes.w, sizes.h);

      ctx.drawImage(layer3, -300, -175, sizes.w * 1.6, sizes.h * 1.6);
      ctx.drawImage(
        WebGLContainer.renderer.domElement,
        -300,
        -175,
        sizes.w * 1.6,
        sizes.h * 1.6
      );

      const imgPath = canvasComp.toDataURL('image/jpeg', 0.97);

      onComplete(imgPath);

      ctx.clearRect(0, 0, canvasComp.width, canvasComp.height);
      layer3.src = '';
    }
  }
};
