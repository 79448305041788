import gsap from 'gsap';
import * as THREE from 'three';
import { DoubleSide } from 'three';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';

import { CanModel } from './CanModel';
import { MeshType } from './config';
import { adjustTexture, getMeshWhereName } from './utils';

export const animateTexturesShader = (
  canModel: CanModel,
  textureFrom: THREE.Texture,
  textureTo: THREE.Texture
) => {
  adjustTexture(textureFrom);
  adjustTexture(textureTo);

  const material = transitionTextures(textureFrom, textureTo);
  getMeshWhereName(canModel.can.children, MeshType.MainMesh).material =
    material;
  getMeshWhereName(
    canModel.can.children,
    MeshType.MainMesh
  ).material.needsUpdate = true;
};

const transitionTextures = (texture, texture2) => {
  const uniforms = {
    map0: { value: texture2 },
    mixVal: { value: 0.0 },
  };

  gsap.timeline({}).to(uniforms.mixVal, {
    value: 1.0,
    duration: 0.9,
    ease: 'cubic-bezier(0, 0.55, 0.45, 1)',
  });

  const material = new THREE.MeshStandardMaterial({
    envMap: WebGLContainer.dictionary.envMap,
    map: texture,
    metalness: 0.9,
    roughness: 0.3,
    side: DoubleSide,
  });

  material.onBeforeCompile = shader => {
    shader.uniforms.map0 = uniforms.map0;
    shader.uniforms.mixVal = uniforms.mixVal;

    shader.fragmentShader = `
    	uniform sampler2D map0;
      uniform float mixVal;
      ${shader.fragmentShader}
    `.replace(
      '#include <map_fragment>',
      `
      #ifdef USE_MAP
      vec4 texelColor0 = texture2D( map0, vUv );
      vec4 texelColor1 = texture2D( map, vUv );
      vec4 texelColor = mix(texelColor0, texelColor1, mixVal);
      diffuseColor *= texelColor;

      #endif
      `
    );
    // console.log(shader.fragmentShader);
  };
  return material;
};
