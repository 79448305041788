import styled from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

import { WrapperMobileOnly, WrapperProps } from '../NonFunctionals.styles';

export const WrapperExtended = styled(WrapperMobileOnly)<WrapperProps>`
  position: relative;
  display: block;

  > .parallaxed {
    position: absolute;
  }
  ${mediaDesktop(`
    > .parallaxed {
      height: 105%;
      width: 105%;
    }
  `)}

  ${mediaTablet(`
    > .parallaxed {
      height: 100%;
      width: 100%;
      background-position: 50% 50%;
    }
  `)}
`;

export const Box = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 60%;
  width: 30%;
  min-height: 1000rem;
  min-width: 1000rem;
  padding-top: 5%;
  transform: scale(0.5) rotate(-15deg) translate(-30%, -45%);

  > svg,
  img:first-of-type {
    width: 70%;
  }
  ${mediaTablet(`
    transform: scale(0.8) rotate(-15deg) translate(-15%, -15%);
      img:last-of-type {
        width: 50%;
      }
    `)}

  ${mediaDesktop(`
      transform: scale(1) rotate(-15deg) translate(10%, 5%);
      img:last-of-type {
        width: 30%;
      }
      `)}


  p {
    color: ${colors.white};
    width: 60%;
    margin: 0 auto;
    text-shadow: -7px 8px 10px rgba(0, 0, 0, 0.25);
  }

  > div {
    p:first-of-type {
      margin: 40rem auto;
      margin: ${({ theme: { locale } }) =>
        locale === 'pk' ? '40rem auto' : '0rem auto 20rem'};
      text-decoration: ${({ theme: { locale } }) =>
        locale === 'pk' ? 'underline' : 'none'};
      font-size: ${({ theme: { locale } }) =>
        locale === 'pk' ? '40rem' : '60rem'};
    }

    p:last-of-type {
      font-size: ${({ theme: { locale } }) =>
        locale === 'pk' ? '35rem' : '40rem'};
    }
  }

  .heading {
    font-size: 90rem !important;
    margin-bottom: 30rem;
  }
`;
