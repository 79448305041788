import React, { FC, useEffect, useRef, useState } from 'react';

import Share from 'components/Share/Share';
import { saveGif } from 'services/gif.service';
import { useCopyStore } from 'store';
import useWindowSize from 'u9/hooks/useWindowSize';

// import GTM from 'u9/utils/gtm';
import { ThreeScene } from './scene/ThreeScene';
import * as Styled from './ThreeView.styled';
import { sleep } from './utils/time';
import { WebGLContainer } from './utils/webglContainer';

export interface ThreeViewProps {
  shareButtons: boolean;
  theme?: any;
}

const defaultProps: Partial<ThreeViewProps> = {
  shareButtons: false,
  theme: null,
};

export const ThreeView: FC<ThreeViewProps> = ({ shareButtons, theme }) => {
  const [mounted, setMounted] = useState(false);
  const [gifSaved, setGifSaved] = useState(false);
  const { copy, userLang } = useCopyStore();

  const windowSize = useWindowSize(true);

  const sceneRef = useRef<any>(null);
  const sceneCanvasRef = useRef<HTMLCanvasElement>(null);

  // const [gifImage, setGifImage] = useState<string | null>(null);

  useEffect(() => {
    setMounted(true);
    // GTM.trackEvent('xxxxxxx', 'xxxxxxxxx', 'xxxxxxxxxxx');
  }, []);

  useEffect(() => {
    if (windowSize && sceneRef.current) {
      sceneRef.current.resize(windowSize.width, windowSize.height);
    }
  }, [windowSize, sceneRef]);

  useEffect(() => {
    if (!mounted) return;

    return () => {
      sceneRef.current?.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  useEffect(() => {
    if (gifSaved) setTimeout(() => setGifSaved(false), 500);
  }, [gifSaved]);

  const buildWebGL = async () => {
    sceneRef.current = new ThreeScene(sceneCanvasRef.current, theme.locale);
  };

  const handleGenerateGif = async (renderer, canvas) => {
    if (canvas) {
      try {
        // setGifImage(await );
        await sleep(600);
        saveGif(
          renderer,
          canvas,
          copy.global.landing.gifTitle,
          setGifSaved,
          userLang
        );
      } catch (error) {
        console.error('Failed to generate GIF:', error);
      }
    }
  };

  // const handleDownloadGif = () => {
  //   if (gifImage) {
  //     downloadGif(gifImage, 'my-gif.gif');
  //   }
  // };

  return (
    <>
      <Styled.Wrapper key="three-wrapper">
        <Styled.CanvasRenderer
          key="canvas-renderer"
          ref={ref => {
            if (sceneCanvasRef.current) return;
            sceneCanvasRef.current = ref;
            WebGLContainer.sceneCanvas = sceneCanvasRef.current;
            buildWebGL();
          }}
        />
      </Styled.Wrapper>

      {shareButtons && (
        <Share
          handleGif={() =>
            handleGenerateGif(
              WebGLContainer.renderer,
              WebGLContainer.sceneCanvas
            )
          }
          gifSaved={gifSaved}
          copy={copy.global.artwork.share}
        />
      )}
    </>
  );
};

ThreeView.defaultProps = defaultProps;

export default ThreeView;
