import Script from 'next/script';
import React from 'react';

const FbPixel: React.FC = () => {
  return (
    <>
      <Script id="FbPixel">
        {` window.fbAsyncInit = function() {
    FB.init({
      appId      : '5832349363495555',
      xfbml      : true,
      version    : 'v17.0'
    });
    FB.AppEvents.logPageView();
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
              `}
      </Script>
    </>
  );
};

export default FbPixel;
