/* eslint-disable simple-import-sort/imports */
const THREE = require('three');

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import EventManager from 'u9/services/eventManager.service';
import { parseUrlQuery } from 'u9/utils/url';
import { CanModel } from './can/CanModel';

import { CAMERA_SETTINGS } from 'constants/scene';
import GUI from 'lil-gui';
import { Lights } from 'components/ThreeView/utils/lights';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Particles } from './Particles';
import { sleep } from 'components/ThreeView/utils/time';

export class World {
  scene: THREE.Scene;
  can: CanModel;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  raycaster: THREE.Raycaster;
  pointer: THREE.Vector2;
  clock: THREE.Clock;
  time: number;
  controls: OrbitControls;
  posRange: number;
  cube: THREE.Mesh;
  isDebug: boolean;
  envMap: THREE.Texture;
  pmremGenerator: THREE.PMREMGenerator;
  particles: Particles;

  gifBg: any;

  inGame: boolean;

  fpsCut: {
    now: number;
    delta: number;
    then: number;
    interval: number;
  };

  locale: string;

  shareableLines: THREE.Sprite;

  constructor(locale: string) {
    this.locale = locale;
    this.scene = WebGLContainer.mainScene;
    this.camera = WebGLContainer.mainCamera;
    this.renderer = WebGLContainer.renderer;

    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    this.clock = new THREE.Clock();
    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();
    this.fpsCut = {
      now: 0,
      delta: 0,
      then: Date.now(),
      interval: 1000 / 30,
    };
    WebGLContainer.fpsMeter = {
      frames: 0,
      prevTime: Date.now(),
      time: Date.now(),
      fps: 0,
    };

    this.isDebug = parseUrlQuery().debugScene === 'true';

    if (this.isDebug || parseUrlQuery().debugColors === 'true') {
      WebGLContainer.isXRRender = false;
      WebGLContainer.dat = new GUI();
      WebGLContainer.dat.close();
    }

    if (!WebGLContainer.eventManager) {
      WebGLContainer.eventManager = new EventManager();
    }

    this.registerEvents();
    //
  }

  registerEvents() {
    // prepare enum for events
    WebGLContainer.eventManager.registerEvent('build_can_scene');
    WebGLContainer.eventManager.registerEvent('intro_can_rotate');
    WebGLContainer.eventManager.registerEvent('intro_can_bottom');
    WebGLContainer.eventManager.registerEvent('show_can_final');
    WebGLContainer.eventManager.registerEvent('hide_can_final');
    WebGLContainer.eventManager.registerEvent('intro_animation');
    WebGLContainer.eventManager.registerEvent('update_can_artwork');
    WebGLContainer.eventManager.registerEvent('restore_initial');
    WebGLContainer.eventManager.registerEvent('export_gif_update_frame');
    WebGLContainer.eventManager.registerEvent('export_gif_complete');
    WebGLContainer.eventManager.registerEvent('export_shareable_start');
    WebGLContainer.eventManager.registerEvent('export_shareable_ready_to_draw');
    WebGLContainer.eventManager.registerEvent('export_shareable_complete');

    document.addEventListener('visibilitychange', this.onVisibilityChange);

    WebGLContainer.eventManager?.on('build_can_scene', () => {
      this.buildCanScene();
    });
    WebGLContainer.eventManager?.on('intro_can_rotate', () => {
      this.can.animateTexturesToInitialState();
      this.can.introCanRotate();
    });
    WebGLContainer.eventManager?.on('intro_can_bottom', async () => {
      this.particles.introCanBottom();
      await sleep(1000);
      this.can.introCanBottom();
    });
    WebGLContainer.eventManager?.on('show_can_final', () => {
      this.can.showCanFinal();
    });
    WebGLContainer.eventManager?.on('hide_can_final', () => {
      this.can.hideCanFinal();
    });
    WebGLContainer.eventManager?.on('update_can_artwork', url => {
      this.can.updateTexture(url, true, true);
    });
    WebGLContainer.eventManager?.on('restore_initial', () => {
      this.can.restoreInitial();
    });
  }

  async buildCanScene() {
    const envMap = this.pmremGenerator.fromEquirectangular(
      WebGLContainer.dictionary.envMap
    ).texture;
    this.envMap = envMap;
    WebGLContainer.dictionary.envMap = this.envMap;
    this.pmremGenerator.dispose();
    this.scene.environment = envMap;
    this.renderer.toneMapping = Number(2);
    this.renderer.toneMappingExposure = Math.pow(2, 2);

    Lights.build();
    this.can = new CanModel(this.locale);

    this.particles = new Particles();

    const geometry = new THREE.PlaneGeometry(50, 50);
    const material = new THREE.ShadowMaterial();
    material.opacity = 0.05;
    const plane = new THREE.Mesh(geometry, material);
    plane.position.z = -20;
    plane.receiveShadow = true;
    WebGLContainer.mainScene.add(plane);

    if (this.isDebug) {
      this.goToCenter();
      this.show();
    }

    this.controls = new OrbitControls(
      this.camera,
      WebGLContainer.renderer.domElement
    );

    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;
    this.controls.screenSpacePanning = false;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 1000;
  }

  show() {
    this.can?.show();
  }

  goToCenter() {
    this.camera.fov = CAMERA_SETTINGS.fov;
    this.camera.updateProjectionMatrix();
  }

  onVisibilityChange = () => {
    if (document.hidden || document.visibilityState === 'hidden') {
      /// TODO add support
    } else {
      /// TODO add support
    }
  };

  preRender() {
    // this.can.preRender();
  }

  prepareToShare(locale: string) {
    // Resize scene to match with Share aspect ratio
    const width = 1200;
    const height = 630;
    WebGLContainer.renderer.domElement.style.width = `${width}px`;
    WebGLContainer.renderer.domElement.style.height = `${height}px`;
    WebGLContainer.renderer?.setSize(width, height);
    WebGLContainer.finalComposer?.setSize(width, height);
    WebGLContainer.mainCamera.aspect = width / height;
    WebGLContainer.mainCamera.updateProjectionMatrix();

    const linesTexture = WebGLContainer.dictionary[`sharableLines_${locale}`];
    const material = new THREE.SpriteMaterial({
      map: linesTexture,
      color: 0xffffff,
    });

    this.shareableLines = new THREE.Sprite(material);
    this.shareableLines.scale.set(2.5, 2.5, 2.5);
    this.shareableLines.position.z = 2.5;
    WebGLContainer.mainScene.add(this.shareableLines);

    this.can?.share(locale);

    if (parseUrlQuery().debugPostPro) {
      const shareF = WebGLContainer.dat.addFolder('Share settings');
      shareF.add(this.can.position, 'x', -Math.PI, Math.PI, 0.01);
      shareF.add(this.can.position, 'y', -Math.PI, Math.PI, 0.01);
      shareF.add(this.can.position, 'z', -Math.PI, Math.PI, 0.01);
      shareF.add(this.can.rotation, 'x', -Math.PI, Math.PI, 0.01);
      shareF.add(this.can.rotation, 'y', -Math.PI, Math.PI, 0.01);
      shareF.add(this.can.rotation, 'z', -Math.PI, Math.PI, 0.01);

      const scale = { value: 1 };
      shareF.add(scale, 'value', 0, 2, 0.01).onChange(() => {
        this.can.scale.set(scale.value, scale.value, scale.value);
      });
    }
  }

  retoreScene() {
    if (!this.shareableLines) return;
    this.shareableLines.material?.dispose();
    WebGLContainer.mainScene.remove(this.shareableLines);
    this.can?.restoreFromShareable(this.locale);
  }

  update() {
    this.fpsCut.now = Date.now();
    this.fpsCut.delta = this.fpsCut.now - this.fpsCut.then;

    //update time dependent animations here at 30 fps
    if (this.fpsCut.delta > this.fpsCut.interval) {
      this.fpsCut.then =
        this.fpsCut.now - (this.fpsCut.delta % this.fpsCut.interval);

      const delta = this.clock.getDelta();

      this.time += delta;
      this.can?.update();
    }

    WebGLContainer.finalComposer?.render();
    this.fpsMeter();
  }

  fpsMeter() {
    WebGLContainer.fpsMeter.time = Date.now();
    WebGLContainer.fpsMeter.frames++;
    if (
      WebGLContainer.fpsMeter.time >
      WebGLContainer.fpsMeter.prevTime + 1000
    ) {
      WebGLContainer.fpsMeter.fps = Math.round(
        (WebGLContainer.fpsMeter.frames * 1000) /
          (WebGLContainer.fpsMeter.time - WebGLContainer.fpsMeter.prevTime)
      );
      WebGLContainer.fpsMeter.prevTime = WebGLContainer.fpsMeter.time;
      WebGLContainer.fpsMeter.frames = 0;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(_w: number, _h: number) {
    // this.mainScene?.onWindowResize(w, h);
  }
}
