import { MeshBasicMaterial, MeshBasicMaterialParameters } from 'three';

export const createBendMaterial = (
  bendFactor = 2,
  materialParams: MeshBasicMaterialParameters = {}
) => {
  const material = new MeshBasicMaterial(materialParams);

  material.onBeforeCompile = shader => {
    shader.uniforms.time = { value: 0 };
    shader.vertexShader = `
      uniform float time;
      ${shader.vertexShader}
    `;
    shader.vertexShader = shader.vertexShader.replace(
      '#include <begin_vertex>',
      `
        vec3 pos = position.xyz;
        float bendFactor = ${bendFactor.toFixed(1)};

        vec2 uv2 = uv - 0.5;
        pos.z = bendFactor * pow(uv2.x, 2.);

        vec3 transformed = vec3( pos );
      `
    );

    material.userData.shader = shader;
  };

  material.customProgramCacheKey = () => {
    return bendFactor.toFixed(1);
  };

  return material;
};
