import create, { UseBoundStore } from 'zustand';

import { Pages } from 'utils/routes';

import { getCopy } from './copy.data';
import { CopyStoreType } from './copy.types';

export const supportedLanguagesForSelector = ['en', 'pk', 'sa']; // ['en', 'vi', 'es'];

export const SupportedLanguages = ['en', 'pk', 'sa'];

const DefaultLanguage = 'en';

export const AdjustToSupportedLanguages = lang =>
  SupportedLanguages.includes(lang) ? lang : DefaultLanguage;

const useCopyStore: UseBoundStore<CopyStoreType> = create(
  (
    set: (partial: (store: CopyStoreType) => Partial<CopyStoreType>) => void
  ) => {
    return {
      userLang: 'en',
      setUserLang: state => {
        set(store => ({
          userLang: (store.userLang = state),
        }));
      },
      setCopy: locale => {
        set(() => ({
          copy: getCopy(Pages.index, AdjustToSupportedLanguages(locale)),
          userLang: locale,
        }));
      },
      copy: getCopy(Pages.index),
    };
  }
);

export default useCopyStore;
