/* eslint-disable simple-import-sort/imports */
import * as THREE from 'three';
import { isAndroid } from 'u9/utils/platform';

import { WebGLContainer } from '../utils/webglContainer';

import { CAMERA_SETTINGS } from 'constants/scene';
import { World } from './objects/World';
import { PostPro } from './PostPro';

export class ThreeScene {
  canvas: HTMLCanvasElement;
  distanceAchieve: boolean;
  world: World;
  locale: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(canvas: HTMLCanvasElement, locale: string) {
    this.locale = locale;
    this.canvas = canvas;
    WebGLContainer.mainScene = new THREE.Scene();

    WebGLContainer.mainCamera = new THREE.PerspectiveCamera(
      CAMERA_SETTINGS.fov,
      (window.innerWidth * 0.5) / (window.innerHeight * 0.5),
      CAMERA_SETTINGS.near,
      CAMERA_SETTINGS.far
    );
    WebGLContainer.mainCamera.position.set(
      CAMERA_SETTINGS.pivot.x,
      CAMERA_SETTINGS.pivot.y,
      CAMERA_SETTINGS.pivot.z
    );

    const width = window.innerWidth * 0.5;
    const height = window.innerHeight * 0.5;

    WebGLContainer.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      powerPreference: 'high-performance',
      antialias: false,
      stencil: false,
      preserveDrawingBuffer: true,
      depth: false,
    });
    let pixelRatio = THREE.MathUtils.clamp(window.devicePixelRatio, 1, 2);
    if (isAndroid()) {
      pixelRatio = window.devicePixelRatio * 0.4;
    }
    WebGLContainer.renderer.setSize(width, height);
    WebGLContainer.renderer.setPixelRatio(pixelRatio);
    WebGLContainer.renderer.shadowMap.enabled = true;
    WebGLContainer.renderer.outputEncoding = THREE.sRGBEncoding;

    WebGLContainer.renderer.setClearColor(0x000000, 0);

    PostPro.setup();

    WebGLContainer.renderer.setAnimationLoop(this.updateCallback.bind(this));

    this.world = new World(this.locale);

    WebGLContainer.eventManager.on('export_shareable_start', () => {
      this.world?.prepareToShare(locale);
    });
    WebGLContainer.eventManager.on('export_shareable_complete', () => {
      this.world?.retoreScene();
      this.resize();
    });
  }

  updateCallback() {
    this.world?.update();
  }

  resize() {
    const width = window.innerWidth * 0.5;
    const height = window.innerHeight * 0.5;
    WebGLContainer.renderer?.setSize(window.innerWidth, window.innerHeight);
    WebGLContainer.finalComposer?.setSize(
      window.innerWidth,
      window.innerHeight
    );
    WebGLContainer.mainCamera.aspect = window.innerWidth / window.innerHeight;
    WebGLContainer.mainCamera.updateProjectionMatrix();
    this.world?.onWindowResize(width, height);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispose = () => {};
}
