import create, { UseBoundStore } from 'zustand';

import { AdjustToSupportedLanguages } from './copy';
import { getVisuals } from './visuals.data';
import { ThemeStoreType } from './visuals.types';

const useThemeStore: UseBoundStore<ThemeStoreType> = create(
  (set: (partial: (store) => Partial<ThemeStoreType>) => void) => {
    const locale = process.env.LOCALE;

    return {
      theme: getVisuals(locale),
      setTheme: locale => {
        set(() => ({
          theme: getVisuals(AdjustToSupportedLanguages(locale)),
        }));
      },
    };
  }
);

export default useThemeStore;
