// TODO: remove unused fonts after implementing all UI

export default `
  @font-face {
    font-family: 'Balboa Ultra Light';
    font-weight: 100;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-UltraLight.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Light';
    font-weight: 200;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-Light.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Medium';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Cond';
    font-weight: 200;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-Condensed.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Extra Cond';
    font-weight: 100;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-ExtraCondensed.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Bold';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-Bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Black';
    font-weight: 800;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-Black.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Extra Black';
    font-weight: 900;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-ExtraBlack.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Balboa Extra Bold';
    font-weight: 900;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Balboa-ExtraBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Roboto Light';
    font-weight: 200;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Roboto-Light.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Roboto Medium';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Roboto-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Roboto Thin';
    font-weight: 100;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Roboto-Thin.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Roboto Bold';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Roboto-Bold.woff2') format('woff2');
  }
`;
