export enum ColorNames {
  white = 'white',
  black = 'black',
  alabaster = 'alabaster',
  alto = 'alto',
  blueRibbon = 'blueRibbon',
  gallery = 'gallery',
  red = 'red',
  orange = 'orange',
  supernova = 'supernova',
  jade = 'jade',
  persimmon = 'persimmon',
  crusta = 'crusta',
  concrete = 'concrete',
}

export enum FontNames {
  BalboaCond = 'BalboaCond',
  // BalboaExtraCond = 'BalboaExtraCond',
  BalboaBold = 'BalboaBold',
  // BalboaBlack = 'BalboaBlack',
  // BalboaExtraBlack = 'BalboaBlack',
  BalboaLight = 'BalboaLight',
  BalboaUltraLight = 'BalboaUltraLight',
  BalboaMedium = 'BalboaMedium',
  RobotoMedium = 'RobotoMedium',
  RobotoLight = 'RobotoLight',
  RobotoThin = 'RobotoThin',
  RobotoBold = 'RobotoBold',
  Arial = 'Arial',
}

const validFontCategories = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'processing',
  'body1',
  'body1Light',
  'body2',
  'selector',
  'bodyErrorPage',
  'bodyModal',
  'button',
  'terms',
] as const;

interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues | Record<string, ResponsiveValues>;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
}

interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.white]: '#fff',
  [ColorNames.black]: '#000',

  [ColorNames.alabaster]: '#fafafa',
  [ColorNames.alto]: '#898989',
  [ColorNames.blueRibbon]: '#6195ED',
  [ColorNames.gallery]: '#eaeaea',
  [ColorNames.red]: '#f00',
  [ColorNames.orange]: '#FF5700',
  [ColorNames.supernova]: '#FFC600',
  [ColorNames.jade]: '#00BA4C',
  [ColorNames.persimmon]: '#FF555A',
  [ColorNames.crusta]: '#FF7933',
  [ColorNames.concrete]: '#F2F2F2',
} as const;

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.BalboaCond]:
      // eslint-disable-next-line quotes
      "'Balboa Cond', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.BalboaLight]:
      // eslint-disable-next-line quotes
      "'Balboa Light', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.BalboaUltraLight]:
      // eslint-disable-next-line quotes
      "'Balboa Ultra Light', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.BalboaBold]:
      // eslint-disable-next-line quotes, prettier/prettier
      "'Balboa Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.BalboaMedium]:
      // eslint-disable-next-line quotes, prettier/prettier
      "'Balboa Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.RobotoMedium]:
      // eslint-disable-next-line quotes, prettier/prettier
      "'Roboto Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.RobotoLight]:
      // eslint-disable-next-line quotes, prettier/prettier
      "'Roboto Light', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.RobotoThin]:
      // eslint-disable-next-line quotes, prettier/prettier
      "'Roboto Thin', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.RobotoBold]:
      // eslint-disable-next-line quotes, prettier/prettier
      "'Roboto Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.Arial]:
      // eslint-disable-next-line quotes, prettier/prettier
      "Arial, 'Helvetica Neue', Helvetica, sans-serif",
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: rem, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont },
    heading1: {
      fontFamily: { default: FontNames.BalboaCond, vi: FontNames.Arial },
      fontSize: { mobile: 80 },
      lineHeight: { mobile: 1.35 },
      fontWeight: 200,
    },
    heading2: {
      fontFamily: { default: FontNames.BalboaLight, vi: FontNames.Arial },
      fontSize: { mobile: 50 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 200,
    },
    heading3: {
      fontFamily: { default: FontNames.BalboaBold, vi: FontNames.Arial },
      fontSize: { mobile: 80 },
      lineHeight: { mobile: 1.05 },
      fontWeight: 700,
    },
    heading4: {
      fontFamily: { default: FontNames.BalboaBold, vi: FontNames.Arial },
      fontSize: { mobile: 120 },
      lineHeight: { mobile: 1.05 },
      fontWeight: 700,
    },
    processing: {
      fontFamily: { default: FontNames.BalboaBold },
      fontSize: { mobile: 80 },
      lineHeight: { mobile: 1.05 },
      fontWeight: 700,
    },
    body1: {
      fontFamily: { default: FontNames.BalboaUltraLight, vi: FontNames.Arial },
      fontSize: { mobile: 50 },
      lineHeight: { mobile: 1.3 },
      fontWeight: 100,
      letterSpacing: 0.03,
    },
    body1Light: {
      fontFamily: { default: FontNames.BalboaLight, vi: FontNames.Arial },
      fontSize: { mobile: 55 },
      lineHeight: { mobile: 1.3 },
      fontWeight: 100,
      letterSpacing: 0.03,
    },
    body2: {
      fontFamily: { default: FontNames.BalboaMedium, vi: FontNames.Arial },
      fontSize: { mobile: 60 },
      lineHeight: { mobile: 1.5 },
      fontWeight: 400,
    },
    bodyErrorPage: {
      fontFamily: { default: FontNames.BalboaUltraLight, vi: FontNames.Arial },
      fontSize: { mobile: 55 },
      lineHeight: { mobile: 1.1 },
      fontWeight: 100,
    },
    bodyModal: {
      fontFamily: { default: FontNames.BalboaUltraLight, vi: FontNames.Arial },
      fontSize: { mobile: 49 },
      lineHeight: { mobile: 1.1 },
      fontWeight: 100,
    },
    button: {
      fontFamily: { default: FontNames.BalboaBold, vi: FontNames.Arial },
      fontSize: { mobile: 80 },
      lineHeight: { mobile: 1.5 },
      fontWeight: 700,
      letterSpacing: 0.03,
      textTransform: 'uppercase',
    },
    selector: {
      fontFamily: { default: FontNames.BalboaCond },
      fontSize: { mobile: 70 },
      lineHeight: { mobile: 1.5 },
      fontWeight: 400,
      letterSpacing: 0.03,
    },
    terms: {
      fontFamily: { default: FontNames.RobotoLight, vi: FontNames.Arial },
      fontSize: { mobile: 35 },
      lineHeight: { mobile: 1.4 },
      fontWeight: 200,
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  borderRadius: 40, // rem
  lineOpacity: 0.1,
  zIndex: {
    languageSelector: 5,
    modals: 6,
    nonFunctionals: 7,
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
