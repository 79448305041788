import { saveAs } from 'file-saver';
import GIF from 'gif.js';

import GifTitleEn from 'assets/img/sharable/gif_title_en.png';
import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';

interface SaveGifOptions {
  delay: number;
  repeat: number;
}

// test this : https://jsfiddle.net/tfoller/zLk24eh5/5/

function saveGif(
  renderer: any,
  canvas: any,
  _copy: string,
  setGifSaved: (boolean) => void,
  locale: string,
  options?: SaveGifOptions
) {
  const width = canvas.width;
  const height = canvas.height;
  const gif = new GIF({
    dither: 'Atkinson-serpentine',
    width: width,
    height: height,
    background: '#000000',
    transparent: null,
    repeat: options?.repeat || 0,
    delay: options?.delay || 0,
  });

  let rAFID;
  const img = new Image();
  const imgTitle = new Image();
  imgTitle.src = GifTitleEn;
  //   locale === 'en' ? GifTitleEn : locale === 'es' ? GifTitleEs : GifTitleVi;

  const canvasResult = document.createElement('canvas');
  canvasResult.width = width;
  canvasResult.height = height;
  const ctx = canvasResult.getContext('2d');

  function draw() {
    ctx.clearRect(0, 0, width, height);
    const scaleFactor = Math.min(width / img.width, height / img.height);

    const multtiplyFactor = window.innerWidth < 400 ? 1.6 : 1.3;
    const newWidth = img.width * scaleFactor * multtiplyFactor;
    const newHeight = img.height * scaleFactor * multtiplyFactor;

    const x = (width - newWidth) / 2;
    const y = (height - newHeight) / 2;

    ctx.drawImage(img, x, y, newWidth, newHeight);
    ctx.drawImage(imgTitle, 0, height * 0.05, width, width * 0.25);
    ctx.drawImage(renderer.domElement, 0, 0, width, height);
    rAFID = requestAnimationFrame(draw);
  }

  img.onload = () => {
    for (let i = 0; i < 40; i++) {
      gif.addFrame(canvasResult, { delay: 75 });
    }

    gif.on('progress', p => {
      WebGLContainer.eventManager.trigger('export_gif_update_frame', {
        frame: p - 0.05,
      });
      WebGLContainer.eventManager.trigger('export_gif_update_frame', {
        frame: p,
      });
      WebGLContainer.eventManager.trigger('export_gif_update_frame', {
        frame: p + 0.05,
      });
    });

    gif.on('finished', (blob: Blob) => {
      cancelAnimationFrame(rAFID);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'MyMirindaCan.gif';
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      WebGLContainer.eventManager.trigger('export_gif_complete');
      setGifSaved(true);
    });

    WebGLContainer.eventManager.trigger('export_gif_update_frame', {
      frame: 0,
    });
    setTimeout(() => {
      draw();
      gif.render();
    }, 300);
  };

  img.src = `/models/gif_background_${locale}.jpg`;
}

function downloadGif(gifImage: string, fileName: string) {
  const byteCharacters = atob(gifImage.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/gif' });
  saveAs(blob, fileName);
}

export { downloadGif, saveGif };
