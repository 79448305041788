//eslint-disable-next-line simple-import-sort/imports
import { PerspectiveCamera, Scene, WebGLRenderer } from 'three';

import EventManager from 'u9/services/eventManager.service';

import { EffectComposer } from 'postprocessing';

interface PreloaderAssetsDictionary {
  can: any;
  canLogo: any;
  envMap: any;
  canAi: any;
  canOriginal: any;
}

interface AssetsDictionary {
  canOriginal: any;
  canAi: any;
  envMap: any;
  can: any;
  canLogo: any;
  particle: any;
  particleHorizontal: any;
  sharableLines_en: any;
  sharableLines_es: any;
  sharableLines_vi: any;
  sharableLines_pk: any;
  gifBg: any;
  light: any;
  base: any;
  baseElements: any;
}

export class WebGLContainer {
  static preloaderAssetsDictionary: PreloaderAssetsDictionary;
  static mainScene: Scene;
  static loaded = false;
  static xrloaded = false;
  static isXRRender = true;
  static sceneCanvas: HTMLCanvasElement;
  static mainCamera: PerspectiveCamera;
  static eventManager: EventManager;
  static cameraLocked = false;
  static animatedElementsMaterials: any;
  static dat: any;
  static dictionary: AssetsDictionary;
  static renderer: WebGLRenderer;
  static finalComposer: EffectComposer;

  static fpsMeter: {
    frames: number;
    time: number;
    prevTime: number;
    fps: number;
  };
}
