import { isTablet } from 'u9/utils/platform';
import { colors } from 'utils/styles/theme';

let visuals = {};
let copyAsPNG = {};

const themes = ['en', 'pk', 'sa']; // vi
const copy = ['en', 'pk', 'sa']; // es

const paths = {
  bg: '/img/bg/',
  errors: '/img/errors/',
  processing: '/img/processing/',
  three: 'models/',
  headings: '/img/headings/',
  ctas: '/img/ctas/',
};

const globals = {
  en: {
    ctaAsText: false,
    headingAsText: false,
  },
  pk: {
    ctaAsText: false,
    headingAsText: false,
  },
  sa: {
    ctaAsText: false,
    headingAsText: false,
  },
  // vi: {
  //   ctaAsText: true,
  //   headingAsText: true,
  // },
  // es: {
  //   ctaAsText: false,
  //   headingAsText: false,
  // },
};

const color = {
  en: {
    bg: colors.supernova,
    font: colors.orange,
  },
  sa: {
    bg: colors.supernova,
    font: colors.orange,
  },
  // es: {
  //   bg: colors.supernova,
  //   font: colors.orange,
  // },
  pk: {
    bg: colors.supernova,
    font: colors.orange,
  },
  // vi: {
  //   bg: colors.jade,
  //   font: colors.persimmon,
  // },
};

copy.map(loc => {
  const mappedCopy = {
    [loc]: {
      headings: {
        landing: `${paths.headings}landing_${loc}.png`,
        intro1: `${paths.headings}intro1_${loc}.png`,
        intro2: `${paths.headings}intro2_${loc}.png`,
        sourceSelection: `${paths.headings}sourceSelection_${loc}.png`,
        artwork: `${paths.headings}artwork_${loc}.png`,
        rotate: `${paths.headings}rotate_${
          isTablet() && loc !== 'pk' ? 'tablet_' : ''
        }${loc}.png`,
        desktop: `${paths.headings}desktop_${loc}.png`,
        modalSelect3: `${paths.headings}modalSelect3_${loc}.png`,
        modalMorePosts: `${paths.headings}modalMorePosts_${loc}.png`,
        modalOops: `${paths.headings}modalOops_${loc}.png`,
        modalNoJS: `${paths.headings}modalNoJS_${loc}.png`,
        modalNoWebGL: `${paths.headings}modalNoWebGL_${loc}.png`,
      },
      ctas: {
        landing: `${paths.ctas}landing_${loc}.png`,
        landingPrivacyButton: `${paths.ctas}landingPrivacyButton_${loc}.png`,
        introContinue: `${paths.ctas}introContinue_${loc}.png`,
        introClose: `${paths.ctas}introClose_${loc}.png`,
        sourceSelection: `${paths.ctas}sourceSelection_${loc}.png`,
        artwork: `${paths.ctas}artwork_${loc}.png`,
        galleryPickerGo: `${paths.ctas}galleryPickerGo_${loc}.png`,
        galleryPickerGallery: `${paths.ctas}galleryPickerGallery_${loc}.png`,
        galleryPickerSelectionManual: `${paths.ctas}galleryPickerSelectionManual_${loc}.png`,
        galleryPickerSelectionAI: `${paths.ctas}galleryPickerSelectionAI_${loc}.png`,
        modalLetsGo: `${paths.ctas}modalLetsGo_${loc}.png`,
        modalTryAgain: `${paths.ctas}modalTryAgain_${loc}.png`,
        modalBackHome: `${paths.ctas}modalBackHome_${loc}.png`,
      },
    },
  };

  return (copyAsPNG = Object.assign(copyAsPNG, mappedCopy));
});

themes.map(loc => {
  const mappedVisuals = {
    [loc]: {
      locale: loc,
      global: {
        colors: {
          bg: color[loc].bg,
          font: color[loc].font,
        },
      },
      three: {
        can: `${paths.three}can_${loc}.glb`,
        canOriginal: `${paths.three}can_${loc}.png`,
        canAi: `${paths.three}canai_${loc}.png`,
        canLogo: `${paths.three}canlogo_${loc}.png`,
        particle: `${paths.three}particle.png`,
        particleHorizontal: `${paths.three}particle_horizontal.png`,
        sharableLinesEn: `${paths.three}textures/sharable_lines_en.png`,
        sharableLinesEs: `${paths.three}textures/sharable_lines_es.png`,
        sharableLinesVi: `${paths.three}textures/sharable_lines_vi.png`,
        sharableLinesPk: `${paths.three}textures/sharable_lines_pk.png`,
      },
      bg: {
        ball: `${paths.bg}ball_${loc}.png`,
        bgPattern: `${paths.bg}bgPattern_${loc}.jpg`,
        logo: `${paths.bg}logo_${loc}.png`,
        bubbles0: `${paths.bg}bubbles0_${loc}.png`,
        bubbles1: `${paths.bg}bubbles1_${loc}.png`,
        citron: `${paths.bg}citron_${loc}.png`,
        emojiGlasses: `${paths.bg}emojiGlasses_${loc}.png`,
        emojiHearts: `${paths.bg}emojiHearts_${loc}.png`,
        emojiTongue: `${paths.bg}emojiTongue_${loc}.png`,
        music: `${paths.bg}music_${loc}.png`,
        stars0: `${paths.bg}stars0_${loc}.png`,
        stars1: `${paths.bg}stars1_${loc}.png`,
        thumbUp: `${paths.bg}thumbUp_${loc}.png`,
      },
      processing: {
        emptyCan: `${paths.processing}emptyCan.png`,
        fullCan: `${paths.processing}fullCan_${loc}.png`,
      },
      errors: {
        rotateBg: `${paths.errors}rotateBg_${loc}.jpg`,
        rotateBgElements: `${paths.errors}rotateBgElements_${loc}.png`,
        desktopBg: `${paths.errors}desktopBg_${loc}.jpg`,
        desktopBgElements: `${paths.errors}desktopBgElements_${loc}.png`,
      },
    },
  };

  return (visuals = Object.assign(visuals, mappedVisuals));
});

export const getVisuals = (locale: string) => {
  const settings = {
    ctaAsText: globals[locale]?.ctaAsText,
    headingAsText: globals[locale]?.headingAsText,
  };

  const theme = locale || 'en';

  return { ...visuals[theme], ...copyAsPNG[locale], ...settings };
};
