import gsap from 'gsap';
import { Sprite, SpriteMaterial } from 'three';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';

import { BaseObject3D } from './BaseObject3D';

export class Particles extends BaseObject3D {
  particles: Sprite[];
  tl: gsap.core.Timeline;
  constructor() {
    super();

    this.particles = [];
    WebGLContainer.mainScene.add(this);

    this.buildParticles();
  }

  buildParticles() {
    const radius = 1.5;
    const total = 21;
    const particleTexture = WebGLContainer.dictionary.particle;
    const material = new SpriteMaterial({
      map: particleTexture,
      color: 0xffffff,
    });

    const plane = new Sprite(material);
    for (let i = 0; i < total; i++) {
      const scale = 2 + Math.random() * 4;
      const planeClone = plane.clone();
      planeClone.position.set(Math.cos(i) * radius, -10, Math.sin(i) * radius);
      planeClone.scale.set(scale, scale, 1);
      this.particles.push(planeClone);
      this.add(planeClone);
    }
  }

  introCanBottom() {
    if (this.tl) {
      this.tl.kill();
    }
    if (!this.tl) {
      this.tl = gsap.timeline({});
      this.tl.addLabel('start');
      this.particles.forEach((p: Sprite, i: number) => {
        this.tl.add(
          gsap.fromTo(
            p.position,
            { y: -10 },
            {
              duration: 1.6,
              delay: (i % 3) + i / 10,
              ease: 'cubic.inOut',
              y: 10,
            }
          ),
          'start'
        );
      });
    }

    this.tl.progress(0);
    this.tl.play();
  }
}
