import React from 'react';

import useThemeStore from 'store/visuals';

export interface HeadingProps {
  title: string;
  imgUrl?: string;
  forceCopy?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  title,
  imgUrl,
  forceCopy,

  ...props
}) => {
  const { theme } = useThemeStore();

  const allProps = {
    className: 'heading',
    key: title,
    ...props,
  };

  const titleComponent = () => {
    const component =
      theme.headingAsText || forceCopy ? (
        <p
          dangerouslySetInnerHTML={{ __html: title }}
          {...allProps}
          style={{
            overflowWrap: theme.locale === 'vi' ? 'anywhere' : 'initial',
          }}
        />
      ) : (
        <img src={imgUrl} alt={title} {...allProps} />
      );
    return component;
  };

  return <>{titleComponent()}</>;
};

export default React.memo(Heading);
