import * as THREE from 'three';

export function disposeObject3D(
  object3d,
  disposeAlsoMaterial = true,
  disposeAlsoTexture = true
) {
  if (!object3d) return;

  if (object3d instanceof THREE.Texture) {
    object3d.dispose();
  } else if (
    object3d instanceof THREE.Mesh ||
    object3d instanceof THREE.SkinnedMesh
  ) {
    if (object3d.geometry) {
      object3d.geometry.dispose();
      // eslint-disable-next-line no-param-reassign
      object3d.geometry = undefined;
    }
    if (object3d.material && disposeAlsoMaterial) {
      if (Array.isArray(object3d.material)) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < object3d.material.length; i++) {
          disposeMaterial(object3d.material[i], disposeAlsoTexture);
          // eslint-disable-next-line no-param-reassign
          object3d.material[i] = undefined;
        }
      } else {
        if (disposeAlsoMaterial)
          disposeMaterial(object3d.material, disposeAlsoTexture);
        // eslint-disable-next-line no-param-reassign
        object3d.material = undefined;
      }
    } // Les this one will be for nested objects. Scene = few models inside.
  } else if (object3d.type === 'Scene') {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < object3d.children.length; i++) {
      disposeObject3D(object3d.children[i]);
      // eslint-disable-next-line no-param-reassign
      object3d.children[i] = undefined;
    }
  } else if (object3d instanceof THREE.Object3D) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < object3d.children.length; i++) {
      disposeObject3D(object3d.children[i]);
      // eslint-disable-next-line no-param-reassign
      object3d.children[i] = undefined;
    }
  }
  // eslint-disable-next-line no-param-reassign
  object3d = undefined;
}

export function disposeMaterial(material, disposeAlsoTexture = true) {
  if (material) {
    if (material.map && disposeAlsoTexture) {
      material.map.dispose();
      // eslint-disable-next-line no-param-reassign
      material.map = undefined;
    }

    if (material.lightMap && disposeAlsoTexture) {
      material.lightMap.dispose();
      // eslint-disable-next-line no-param-reassign
      material.lightMap = undefined;
    }

    if (material.bumpMap && disposeAlsoTexture) {
      material.bumpMap.dispose();
      // eslint-disable-next-line no-param-reassign
      material.bumpMap = undefined;
    }

    if (material.normalMap && disposeAlsoTexture) {
      material.normalMap.dispose();
      // eslint-disable-next-line no-param-reassign
      material.normalMap = undefined;
    }

    if (material.specularMap && disposeAlsoTexture) {
      material.specularMap.dispose();
      // eslint-disable-next-line no-param-reassign
      material.specularMap = undefined;
    }

    if (material.envMap && disposeAlsoTexture) {
      material.envMap.dispose();
      // eslint-disable-next-line no-param-reassign
      material.envMap = undefined;
    }
    material.dispose();
    // eslint-disable-next-line no-param-reassign
    material = undefined;
  }
}
