import Script from 'next/script';
import React, { useState } from 'react';

import * as Styled from './TrustArcComponent.styles';

const Trustarc: React.FC = () => {
  const [isTest] = useState(false);
  return (
    <Styled.Wrapper>
      {isTest ? (
        <>
          <Script
            async={true}
            src="//consent.trustarc.com/notice?domain=pepsico-mirinda.com&c=teconsent&js=nj&noticeType=bb&country=fr&language=en"
            crossOrigin=""
          />
          <div id="consent_blackbar"></div>
        </>
      ) : (
        <>
          <Script
            async={true}
            src="//consent.trustarc.com/notice?domain=pepsico-mirinda.com&c=teconsent&gtm=1&text=true&js=nj&noticeType=bb"
            crossOrigin="anonymous"
          />
          <div id="consent_blackbar"></div>
        </>
      )}
    </Styled.Wrapper>
  );
};

export default Trustarc;
