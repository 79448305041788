import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 100vw;

  > section {
    position: fixed;
    bottom: 20%;
    right: 0;
    ${({ theme: { locale } }) => setTypography('body2', locale)}
    display: flex;
    flex-direction: column;
    margin: 50rem;
    z-index: ${layout.zIndex.languageSelector};

    > button {
      margin: 15rem;
      height: 135rem;
      width: 135rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.white};

      > svg {
        height: 65rem;
        ${({ theme }) => `color: ${theme.global.colors.font};`}
      }
    }
  }
`;
