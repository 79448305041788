/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Mesh, Object3D } from 'three';

import { disposeObject3D } from 'components/ThreeView/utils/assetDisposer';

interface IBaseObject3D {
  show(): void;
  hide(): void;
}

export class BaseObject3D extends Object3D implements IBaseObject3D {
  constructor() {
    super();
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  dispose() {
    this.children.map(child => {
      if (child instanceof Mesh || child instanceof Object3D) {
        disposeObject3D(child);
      }
    });
  }
}
