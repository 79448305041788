import { addEventListeners } from 'u9/utils/dom';

export class CanModelMovment {
  private lastTouchX = 0;
  private can = null;

  constructor(can) {
    this.can = can;

    addEventListeners(window, 'mousedown touchstart', this.onTouchStart);
    addEventListeners(window, 'mousemove touchmove', this.onTouchMove);
    addEventListeners(window, 'mouseend touchend', this.onTouchend);
  }

  onTouchStart = event => {
    event.preventDefault();
    if (this.can.ableTodDrag && event.touches) {
      this.can.shouldAutorotate = false;
      this.lastTouchX = event.touches[0].clientX;
    }
  };

  onTouchMove = event => {
    event.preventDefault();
    if (this.can.ableTodDrag && event.touches) {
      this.can.shouldAutorotate = false;
      this.can.rotation.y +=
        (event.touches[0].clientX - this.lastTouchX) * 0.01;
      this.lastTouchX = event.touches[0].clientX;
    }
  };

  onTouchend = () => {
    this.can.shouldAutorotate = true;
  };
}
