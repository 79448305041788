import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import dots from 'assets/img/dots.png';
import hash0 from 'assets/img/intro/hash/hash0.png';
import hash1 from 'assets/img/intro/hash/hash1.png';
import hash2 from 'assets/img/intro/hash/hash2.png';
import hash3 from 'assets/img/intro/hash/hash3.png';
import hash4 from 'assets/img/intro/hash/hash4.png';
import hash5 from 'assets/img/intro/hash/hash5.png';
import ThreeView from 'components/ThreeView/ThreeView';
import { useCopyStore, useGlobalStore } from 'store';
import useThemeStore from 'store/visuals';
import { SPA_ROUTES } from 'utils/routes';
import { fadeMotionProps } from 'utils/styles/animations';

import * as Styled from './AppBackground.styles';

export interface AppBackgroundProps {
  shareButtons?: boolean;
}

const defaultProps: Partial<AppBackgroundProps> = {
  shareButtons: false,
};

const AppBackground: React.FC<AppBackgroundProps> = ({ shareButtons }) => {
  const {
    currentPage,
    processingPreEnterAnim,
    playProcessingExitAnim,
    backgroundUpAnim,
  } = useGlobalStore();
  const { userLang } = useCopyStore();
  const { theme } = useThemeStore();

  const [showEnterAnim, setShowEnterAnim] = useState(false);
  const [rotateIconsAroundCanAnim, playRotateIconsAroundCanAnim] =
    useState(false);
  const [rotateHashAroundCanAnim, playRotateHashAroundCanAnim] =
    useState(false);
  const [hashAroundCanExitAnim, playHashAroundCanExitAnim] = useState(false);
  const [centerLogoAnim, playCenterLogoAnim] = useState(false);
  const [lowerLogoAnim, playLowerLogoAnim] = useState(false);
  const [lowerLogoGalleryPickerAnim, playLogoGalleryPickerAnim] =
    useState(false);
  const timeOutA = useRef(null);
  const timeOutB = useRef(null);
  const timeOutC = useRef(null);

  useEffect(() => {
    playProcessingExitAnim(true);
    setTimeout(() => {
      playProcessingExitAnim(false);
      setShowEnterAnim(
        currentPage === SPA_ROUTES.LOADER ||
          currentPage === SPA_ROUTES.PROCESSING ||
          currentPage === SPA_ROUTES.ARTWORK ||
          currentPage === SPA_ROUTES.GALLERYPICKER
      ),
        1000;
    });

    if (currentPage === SPA_ROUTES.INTRO) {
      if (theme.locale === 'vi') playLowerLogoAnim(true); // Prepared for lower logo
      timeOutA.current = setTimeout(() => {
        playRotateHashAroundCanAnim(true);
      }, 1000);
      timeOutB.current = setTimeout(() => {
        playRotateHashAroundCanAnim(false);
        playHashAroundCanExitAnim(true);
      }, 3000);
      timeOutB.current = setTimeout(() => {
        playRotateIconsAroundCanAnim(true);
      }, 3500);
      setTimeout(() => {
        playHashAroundCanExitAnim(false);
        playRotateIconsAroundCanAnim(false);
      }, 6500);
    }

    if (currentPage === SPA_ROUTES.LANDING) {
      clearTimeout(timeOutA.current);
      clearTimeout(timeOutB.current);
      clearTimeout(timeOutC.current);
      playRotateHashAroundCanAnim(false);
      playHashAroundCanExitAnim(false);
      playRotateIconsAroundCanAnim(false);
    }

    if (theme.locale === 'vi') {
      if (currentPage === SPA_ROUTES.GALLERYPICKER) {
        playLowerLogoAnim(false);
        playLogoGalleryPickerAnim(true);
      }
      if (currentPage === SPA_ROUTES.SOURCESELECTION) {
        playLowerLogoAnim(true);
        playLogoGalleryPickerAnim(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, userLang]);

  useEffect(() => {
    if (backgroundUpAnim && theme.locale === 'en')
      setTimeout(() => playCenterLogoAnim(true), 2600);
    if (currentPage !== SPA_ROUTES.SOURCESELECTION) playCenterLogoAnim(false);
  }, [backgroundUpAnim, currentPage, theme.locale]);

  return (
    <Styled.Wrapper
      $centerLogo={centerLogoAnim}
      $processingIntroAnim={showEnterAnim}
      $shrinkExpandAnims={processingPreEnterAnim}
      $rotateIconsAroundCanAnim={rotateIconsAroundCanAnim}
      $rotateHashAroundCanAnim={rotateHashAroundCanAnim}
      $hashAroundCanExitAnim={hashAroundCanExitAnim}
      $backgroundUpAnim={backgroundUpAnim}
      $lowerLogoAnim={lowerLogoAnim}
      $regenerateLogoAnim={lowerLogoGalleryPickerAnim}
      $theme={theme.global}
      $locale={theme.locale}
    >
      <img src={dots} alt="bgPattern" />
      <img src={theme?.bg?.logo} alt="logo" />

      <ThreeView
        shareButtons={shareButtons || currentPage === SPA_ROUTES.ARTWORK}
        theme={theme}
      />

      <AnimatePresence exitBeforeEnter={true}>
        {currentPage !== SPA_ROUTES.LOADER &&
        currentPage !== SPA_ROUTES.PROCESSING &&
        currentPage !== SPA_ROUTES.ARTWORK &&
        currentPage !== SPA_ROUTES.GALLERYPICKER ? (
          <motion.section
            className="landing"
            key="landing"
            {...fadeMotionProps}
          >
            <img src={theme?.bg?.ball} alt="" className="ball0" />
            <img src={theme?.bg?.ball} alt="" className="ball1" />
            <img src={theme?.bg?.citron} alt="" className="citron" />
            <img src={theme?.bg?.bubbles0} alt="" className="bubbles0" />
            <img src={theme?.bg?.bubbles1} alt="" className="bubbles1" />
            <div className="additionalBubbles">
              <img src={theme?.bg?.bubbles0} alt="" className="bubblesA0" />
              <img src={theme?.bg?.bubbles0} alt="" className="bubblesA1" />
              <img src={theme?.bg?.bubbles1} alt="" className="bubblesA2" />
            </div>
            <div className="hashWrapper">
              <img src={hash0} alt="" className="hash0" />
              <img src={hash1} alt="" className="hash1" />
              <img src={hash2} alt="" className="hash2" />
              <img src={hash3} alt="" className="hash3" />
              <img src={hash4} alt="" className="hash4" />
              <img src={hash5} alt="" className="hash5" />
            </div>
            <img
              src={theme?.bg?.emojiGlasses}
              alt=""
              className="emojiGlasses"
            />
            <img src={theme?.bg?.emojiTongue} alt="" className="emojiTongue" />
            <img src={theme?.bg?.music} alt="" className="music" />
            <img src={theme?.bg?.thumbUp} alt="" className="thumbUp" />
          </motion.section>
        ) : (
          <motion.section
            className="artwork"
            key="artwork"
            {...fadeMotionProps}
          >
            <div className="expand1">
              <img src={theme?.bg?.bubbles0} alt="" className="bubbles0" />
              <img src={theme?.bg?.bubbles1} alt="" className="bubbles1" />
              <img src={theme?.bg?.bubbles0} alt="" className="bubbles2" />
            </div>
            <img src={theme?.bg?.citron} alt="" className="citron0" />
            <img src={theme?.bg?.citron} alt="" className="citron1" />
            <div className="expand0">
              <img
                src={theme?.bg?.emojiHearts}
                alt=""
                className="emojiHearts"
              />
              <img
                src={theme?.bg?.emojiTongue}
                alt=""
                className="emojiTongue"
              />
              <img src={theme?.bg?.stars0} alt="" className="stars0" />
              <img src={theme?.bg?.stars1} alt="" className="stars1" />
            </div>
            <img src={theme?.bg?.ball} alt="" className="ball0" />
            <img src={theme?.bg?.ball} alt="" className="ball1" />
          </motion.section>
        )}
      </AnimatePresence>
    </Styled.Wrapper>
  );
};

AppBackground.defaultProps = defaultProps;

export default AppBackground;
