import { motion } from 'framer-motion';
import styled from 'styled-components';

import { fullscreenWhite, setTypography, setVh } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  z-index: ${layout.zIndex.nonFunctionals};
  min-height: ${setVh(100)};
  width: 100vw;
  position: relative;

  > div:last-of-type {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      width: 75%;
      margin-bottom: 10%;
    }
  }
`;

export interface WrapperProps {
  $bgUrl: string;
  $bgElementsUrl: string;
  $theme: any;
}

export const WrapperMobileOnly = styled(motion.div)<WrapperProps>`
  ${fullscreenWhite()}
  z-index: ${layout.zIndex.nonFunctionals};
  background-image: url(${props => props.$bgUrl});
  background-size: cover;

  > div {
    background-image: url(${props => props.$bgElementsUrl});
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      width: 75%;
      margin-bottom: 10%;
    }
  }
`;

export const Title = styled.h1`
  ${({ theme: { locale } }) => setTypography('heading1', locale)}
`;

export const Description = styled.p`
  white-space: pre-wrap;
  text-align: center;
  font-size: 50rem;
`;

export const Card = styled.div`
  background-color: ${colors.white};
  width: 86%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 70rem 15rem 50rem;
  border-radius: 5px;
  ${({ theme: { locale } }) => setTypography('bodyErrorPage', locale)}
  color: ${colors.orange};

  > svg {
    margin-top: 20rem;
    margin-bottom: 50rem;
    height: 160rem;
  }

  > img {
    margin: 30rem auto 50rem;
    width: 80%;
  }

  p {
    margin-bottom: 50rem;
    padding: 0 10%;
  }
`;
