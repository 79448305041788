import Cookies from 'js-cookie';
import {
  DoubleSide,
  FrontSide,
  Group,
  Mesh,
  MeshPhysicalMaterial,
  PlaneGeometry,
  TextureLoader,
} from 'three';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { Event } from 'u9/services/eventManager.service';
import { NAMESPACE } from 'utils/localStorage';

import { createBendMaterial } from '../../shaders/bendMaterial';
import { BaseObject3D } from '../BaseObject3D';
import {
  hideCanFinalAnim,
  introCanBottomAnim,
  introCanRotateAnim,
  restoreInitialAnim,
  showCanFinalAnim,
} from './animations';
import { baseScale, baseScaleIntro, MeshType } from './config';
import { CanModelMovment } from './touchUtil';
import { animateTexturesShader } from './TransitionTextures';
import { getCanMaterial, getMeshWhereName } from './utils';

const bigerCan = ['vi', 'pk'];
const bigerCanShare = ['vi', 'pk'];
export class CanModel extends BaseObject3D {
  can: any;
  arrows: Group;
  arrowTop: any;
  arrowBottom: any;
  textureLoader: TextureLoader;
  animTL: gsap.core.Timeline;
  shouldAutorotate: boolean;
  isLogoAdded: boolean;
  canModelMovment: CanModelMovment;
  ableTodDrag: boolean;
  locale: string;
  logoMaterial: MeshPhysicalMaterial;
  logoMesh: Mesh;

  isBiggerCan = locale => bigerCan.includes(locale);
  isBiggerCanOnShare = locale => bigerCanShare.includes(locale);

  constructor(locale: string) {
    super();
    this.locale = locale;

    this.textureLoader = new TextureLoader();
    this.textureLoader.crossOrigin = 'Anonymous';

    this.shouldAutorotate = false;
    this.isLogoAdded = false;

    // const axesHelper = new AxesHelper(10);
    // this.add(axesHelper);
    this.buildCan();

    WebGLContainer.mainScene.add(this);
    this.changeAbleToDrag(false);
    this.restoreInitial();

    WebGLContainer.eventManager.on('export_gif_complete', () => {
      this.can.scale.set(baseScale, baseScale, baseScale);
      this.can.rotation.set(0, 0, 0);
      this.can.position.x = 0;
      this.shouldAutorotate = true;
      this.changeAbleToDrag(true);
    });
    WebGLContainer.eventManager.on('export_gif_start', () => {
      this.changeAbleToDrag(false);
      this.shouldAutorotate = false;
      this.can.position.x = 0.4;
      this.can.rotation.z = 0.3;
      this.can.rotation.y = 0;
      this.can.scale.set(baseScaleIntro, baseScaleIntro, baseScaleIntro);
    });
    WebGLContainer.eventManager.on(
      'export_gif_update_frame',
      (event: Event) => {
        this.changeAbleToDrag(false);
        const PI_2 = Math.PI * 2;
        this.shouldAutorotate = false;
        this.can.position.x = 0.4;
        this.can.rotation.z = 0.3;
        this.can.rotation.y = 0;
        this.rotation.y = -PI_2 * event.data.frame;
      }
    );
  }

  update() {
    if (this.can && this.ableTodDrag) {
      if (this.shouldAutorotate) this.rotation.y += -0.04;
    }
  }

  updateMaterialAndShowCanFinal(texture, animate) {
    const mainMesh = getMeshWhereName(this.can.children, MeshType.MainMesh);
    mainMesh.material = getCanMaterial(texture);
    mainMesh.material.needsUpdate = true;
    mainMesh.castShadow = true;

    if (animate) this.showCanFinal();
  }

  updateTexture(data, animate = true, addLogo = false) {
    const handleTextureLoad = texture => {
      this.updateMaterialAndShowCanFinal(texture, animate);
    };

    const handleError = error => {
      console.log('An error occurred while loading the texture:', error);
    };

    if (typeof data.data === 'string') {
      this.textureLoader.load(
        data.data,
        handleTextureLoad,
        undefined,
        handleError
      );
    } else {
      handleTextureLoad(data.data);
    }

    if (addLogo) {
      this.addLogo();
      this.isLogoAdded = true;
      this.changeAbleToDrag(true);
      this.canModelMovment = new CanModelMovment(this);
    }
  }

  animateTextures = () => {
    const texture1 = WebGLContainer.dictionary.canAi;
    const texture2 = WebGLContainer.dictionary.canOriginal;
    animateTexturesShader(this, texture1, texture2);
    this.addLogo();
    this.isLogoAdded = true;
    this.changeAbleToDrag(true);
  };

  animateTexturesToInitialState = () => {
    const texture1 = WebGLContainer.dictionary.canOriginal;
    const texture2 = WebGLContainer.dictionary.canAi;
    animateTexturesShader(this, texture1, texture2);
    this.hideLogo();
    this.isLogoAdded = false;
    this.changeAbleToDrag(false);
  };

  addLogo = () => {
    const textureLogo = WebGLContainer.dictionary.canLogo;
    this.logoMaterial = getCanMaterial(textureLogo);
    this.logoMaterial.transparent = true;

    this.logoMesh = getMeshWhereName(this.can.children, MeshType.LogoMesh);

    this.logoMesh.material = this.logoMaterial;
    if (this.locale === 'es') {
      this.logoMesh.rotation.z = -1.8707963267948968;
    }
    this.logoMesh.material.opacity = 1;
    this.logoMesh.material.depthTest = false;

    this.logoMesh.material.side = FrontSide;
    // BackSide will be used for MX / VI in case of adding it back
    // this.logoMesh.material.side = this.locale === 'en' ? FrontSide : BackSide;

    this.logoMesh.material.needsUpdate = true;
  };

  hideLogo() {
    this.logoMesh = getMeshWhereName(this.can.children, MeshType.LogoMesh);
    if (this.logoMesh) {
      (this.logoMesh.material as MeshPhysicalMaterial).opacity = 0;
    }
  }

  buildCan() {
    this.can = WebGLContainer.dictionary.can['scene'].clone();
    this.add(this.can);

    this.buildAnimatedArrows();

    // update textures with envMap
    for (let index = 0; index < this.can.children.length - 1; index++) {
      this.can.children[index].material.envMap =
        WebGLContainer.dictionary.envMap;
      this.can.children[index].material.needsUpdate = true;
    }
    this.updateTexture({ data: WebGLContainer.dictionary.canOriginal }, false);
  }

  buildAnimatedArrows() {
    this.arrows = new Group();
    this.add(this.arrows);

    const arrowTopGeo = new PlaneGeometry(1.7, 1.7, 32, 1);
    this.arrowTop = new Mesh(
      arrowTopGeo,
      createBendMaterial(1.8, {
        map: WebGLContainer.dictionary.particleHorizontal,
        side: DoubleSide,
        transparent: true,
        opacity: 0,
      })
    );

    const languagePreference = Cookies.get(`${NAMESPACE}.userLanguage`);
    this.arrowTop.position.set(
      0,
      languagePreference !== this.isBiggerCan(this.locale) ? 3 : 3.4,
      -0.8
    );
    this.arrows.add(this.arrowTop);

    this.arrowBottom = new Mesh(
      arrowTopGeo.clone(),
      createBendMaterial(1.8, {
        map: WebGLContainer.dictionary.particleHorizontal,
        side: DoubleSide,
        transparent: true,
        opacity: 0,
      })
    );
    this.arrowBottom.position.set(0, 0, 0.8);
    this.arrowBottom.rotation.set(0, Math.PI, 0);
    this.arrows.add(this.arrowBottom);
  }

  // Animations
  introCanRotate = () => {
    this.hideLogo();
    introCanRotateAnim(this);
  };

  introCanBottom = () => introCanBottomAnim(this);

  showCanFinal = () => showCanFinalAnim(this);

  hideCanFinal = () => hideCanFinalAnim(this);

  restoreInitial = () => restoreInitialAnim(this);

  share = (locale: string) => {
    this.shouldAutorotate = false;
    this.changeAbleToDrag(false);
    this.position.set(
      this.isBiggerCanOnShare(locale) ? -0.71 : -0.63,
      this.isBiggerCanOnShare(locale) ? -1.55 : -1.32,
      0
    );
    this.rotation.set(
      this.isBiggerCanOnShare(locale) ? 0.82 : 0.53,
      this.isBiggerCanOnShare(locale) ? 0.71 : 0.07,
      this.isBiggerCanOnShare(locale) ? -0.46 : -0.35
    );
    this.scale.set(0.85, 0.85, 0.85);
    this.can.rotation.set(0, 0, 0);

    WebGLContainer.eventManager.trigger('export_shareable_ready_to_draw');
  };

  restoreFromShareable = (locale: string) => {
    this.position.set(0, this.isBiggerCan(locale) ? -2.2 : -1.6, 0);
    this.rotation.set(0, 0, 0);
    this.scale.set(1, 1, 1);
    this.shouldAutorotate = true;
    this.changeAbleToDrag(true);
  };

  changeAbleToDrag = (status: boolean) => {
    this.ableTodDrag = status;
  };
}
