export const PHASE_TYPES = {
  INTRO: 'intro',
  FINAL: 'final',
};

export const DRAW_TEXT_CANVAS = {
  size: 512,
};

export const VALID_MOTION_TYPES = [
  PHASE_TYPES.INTRO,
  PHASE_TYPES.FINAL,
] as const;

export const CAMERA_SETTINGS = {
  filmGauge: 1.0,
  fov: 75,
  near: 0.1,
  far: 1000,
  fog: 164,
  pivot: { x: 0, y: 0, z: 5 },
  target: { x: 0, y: 0, z: 0 },
};

export const LIGHT_SETTINGS = {
  ambient: {
    color: 0xababab,
    intensity: 0.3,
  },
  directional: {
    color: '#0ffffff',
    intensity: 0.409,
    distance: 10,
    position: {
      x: 3.149,
      y: 30.634,
      z: -6.06,
    },
  },
};
