import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { generateLinkToShare } from 'components/Share/Share.utils';
import useWindowSize from 'u9/hooks/useWindowSize';
import { isAndroid, isInputFocused } from 'u9/utils/platform';

export interface HeadProps {
  title: string;
  description: string;
  ogType: string;
  shareId: string;
  ogImage: {
    url: string;
  };
  ogTitle: {
    copy: string;
  };
  ogDescription: {
    copy: string;
  };
}

// eslint-disable-next-line prettier/prettier
const defaultViewport = `width=device-width, initial-scale=1.0, shrink-to-fit=no${
  process.env.ALLOW_USER_ZOOM
    ? ''
    : ',minimum-scale=1.0,maximum-scale=1.0,user-scalable=0'
}, viewport-fit=cover`;

const Head: React.FunctionComponent<HeadProps> = ({
  title,
  description,
  ogType,
  shareId,
  ogImage,
  ogTitle,
  ogDescription,
}) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const [viewport, setViewport] = useState<string>(defaultViewport);
  const router = useRouter();
  const windowSize = useWindowSize();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // Prevent Android keyboard shrinking the viewport
    if (isAndroid() && !isInputFocused()) {
      setViewport(
        // eslint-disable-next-line prettier/prettier
        `height=${windowSize.height},width=${
          windowSize.width
        },initial-scale=1.0${
          process.env.ALLOW_USER_ZOOM
            ? ''
            : ',minimum-scale=1.0,maximum-scale=1.0,user-scalable=0'
        }`
      );
    }
  }, [windowSize]);

  // copied form main.py
  const origins = [
    'https://u9ltd-project-3278-mirind-c3bd-development-ojjuec5x2q-ew.a.run.app',
    'https://u9ltd-project-3278-mirind-c3bd-test-ojjuec5x2q-ew.a.run.app',
    'https://3278-mirinda-ai-can-frontend-staging.unit9.net',
    'https://mirindaflavourgenerator-com-frontend.wspuat.com',
    'https://mirindaflavourgenerator-com-frontend.wspprod.com',
    'https://mirindaflavourgenerator.com',
    'https://www.mirindaflavourgenerator.com',
  ];

  const fullPath = useMemo(
    () =>
      isMounted && origins.includes(window.location.origin)
        ? 'https://mirindaflavourgenerator.com'
        : '',
    [isMounted, router?.asPath]
  );

  const domain = useMemo(
    () => (isMounted && window.location.host) || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router?.asPath]
  );

  return (
    <NextHead>
      <title>{title || ''}</title>
      <meta name="description" content={description || ''} />

      <link rel="icon" href={'/favicon.ico'} />
      <link
        rel="manifest"
        href={'/manifest.json'}
        crossOrigin="use-credentials"
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={'/favicon-32x32.png'}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={'/favicon-16x16.png'}
      />
      <link rel="mask-icon" href={'/safari-pinned-tab.svg'} />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={'/apple-touch-icon.png'}
      />

      <meta property="og:url" content={fullPath} />
      <meta property="og:type" content={ogType || ''} />
      <meta property="og:title" content={ogTitle.copy || ''} />
      <meta property="og:description" content={ogDescription.copy || ''} />

      <meta
        property="og:image"
        itemProp="image"
        content={generateLinkToShare(ogImage.url, shareId)}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/jpg" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={fullPath} />
      <meta property="twitter:title" content={ogTitle.copy || ''} />
      <meta property="twitter:description" content={ogDescription.copy || ''} />

      <meta
        name="twitter:image"
        content={generateLinkToShare(ogImage.url, shareId)}
      />

      <meta name="viewport" content={viewport} />
    </NextHead>
  );
};

export default Head;
