import isValidProp from '@emotion/is-prop-valid';
// import * as Sentry from '@sentry/react';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import Error from 'next/error';
import Script from 'next/script';
import React, { useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';

import FbPixel from 'components/TrustArc/FbPixel';
import Trustarc from 'components/TrustArc/TrustArcComponent';
import { loadCaptcha } from 'services/captcha';
import { setupStoreDevTools, useCopyStore } from 'store';
import { getCopy } from 'store/copy.data';
import useThemeStore from 'store/visuals';
import ErrorBoundary from 'u9/components/ErrorBoundary/ErrorBoundary';
import Head from 'u9/components/Head/Head';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import NonFunctionals from 'u9/containers/NonFunctionals/NonFunctionals';
import GTM from 'u9/utils/gtm';
import { Pages } from 'utils/routes';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';

import 'intersection-observer';

// if (process.env.ENV !== 'local') {
//   Sentry.init({
//     enabled: process.env.NODE_ENV !== 'development',
//     dsn: process.env.SENTRY_DSN,
//     environment: process.env.ENV,
//   });
// }

const App = ({ Component, pageProps, router, props }: any) => {
  const [isMounted, setMounted] = useState(false);
  const { setCopy, setUserLang } = useCopyStore();
  const { theme, setTheme } = useThemeStore();

  const ErrorBoundaryComponent = useMemo(
    () => (process.env.ENV === 'local' ? ErrorBoundary : ErrorBoundary),
    []
  );

  useEffect(() => {
    if (isMounted) {
      setTimeout(() => {
        TagManager.initialize({ gtmId: 'GTM-KHS4GDW' });
      }, 1000);

      setTimeout(() => {
        GTM.initialize(true);
      }, 2000);
    }
  }, [isMounted]);

  useEffect(() => {
    setCopy(props.locale);
    setUserLang(props.locale);
    setTheme(props.locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.locale]);

  useEffect(() => {
    setupStoreDevTools();
    loadCaptcha();
    setMounted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={{ ...theme, locale: props?.locale }}>
        <MotionConfig isValidProp={isValidProp}>
          <GlobalStyles />
          <style dangerouslySetInnerHTML={{ __html: customFonts }} />

          <ErrorBoundaryComponent
            {...(process.env.ENV !== 'local'
              ? { fallback: <Error statusCode={isMounted ? 400 : 500} /> }
              : {})}
          >
            <Head {...props.copy.head} shareId={props.share} />
            <NonFunctionals copy={props.copy.global} router={router}>
              <AnimatePresence exitBeforeEnter={true} initial={false}>
                <Component
                  copy={props.copy.global}
                  locale={props?.locale}
                  {...pageProps}
                />
              </AnimatePresence>
            </NonFunctionals>
            <Trustarc />
            <FbPixel />

            <Script id="trust-event" src="/trust-event-tag.js" />
            {/* <Script
              id="GTM-event"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KHS4GDW');`,
              }} */}
          </ErrorBoundaryComponent>

          {process.env.IS_DEBUG && <Version />}
          {isMounted && window.location.hash === '#app_version' && (
            <VersionScreen />
          )}
        </MotionConfig>
      </ThemeProvider>
    </React.StrictMode>
  );
};

App.getInitialProps = context => {
  const { query, asPath } = context.ctx;
  // extract headers
  const matches = asPath.match(/\/([^\\/]{0,2})/);
  const market = matches?.[1] || 'en';
  const locale = market === 'pk' ? 'pk' : 'en';
  // const locale = market === 'vn' ? 'vi' : market === 'mx' ? 'es' : 'en';

  const copyMain = getCopy(Pages.index, locale);
  return {
    props: {
      copy: copyMain,
      share: query.share,
      headers: context.req?.headers || {},
      locale,
    },
  };
};

export default App;
